export default {
    //导航栏
    navigation: {
        home: "主页",//主页
        findTalents: "找人才",//找人才
        pastProjects: "案例列表",//案例列表
        postedProjects: "接任务",//接任务
        reviews: "好评人才",//好评人才
        blogs: "博客",//博客
        contactUs: "联系我们",//联系我们
        mainService: "主要服务",//主要服务
        financialTechnologyReporting: "金融科技 - 报告",//金融科技-报告
        cloudServices: "云服务",//云服务
        webCraw: "网络爬虫",//网络爬虫
        businessInteligence: "商业智能",//商业智能
        chatGPT: "Chat-GPT",//Chat-GPT
        search: "搜索",//搜索
        login: "登录",//登录

        myCenter: "个人中心",//个人中心
        homePage: "个人主页",//个人主页
        loginOut: "退出",//退出

        register: "注册",//注册
    },

    //主页轮播
    wheelSeeding: {
        firstTitle: "全球人才覆盖",
        firstContent: "全球人才覆盖 - 通过基于项目的招聘和付款雇用软件开发人员、QA 工程师和产品经理。",
        firstButton: "立即注册",
        secondTitle: "人才济济",
        secondContent: "超过30k +候选人可用，满足您的所有项目需求, 保证成功和满意。",
        secondButton: "了解更多信息",
        thirdTitle: "大量优秀案例",
        thirdContent: "有超过40，000个成功案例和4，000多个正面评价",
        thirdButton: "浏览案例",
    },

    //欢迎部分
    welcome: {
        targetTitle: "我们的目标",
        targetContent: "我们的目标是以合理、灵活和初创企业友好的价格为加拿大初创企业提供优质的软件开发服务、解决方案和人才。",
        visionTitle: "我们的愿景",
        visionContent: "我们的目标是通过人工智能和区块链技术的最新发展彻底改变软件开发、协作和管理的过程。",
        valuesTitle: "我们的价值观",
        valuesContent: "以最灵活和最有效的方式为加拿大企业提供丰富的人才，以促进更高水平的创新、发展和合作。",
    },

    why: {
        //标题
        whyTitle: "我们的服务和承诺",
        whyContent: "通过创新的流程和协作提供可扩展和灵活的人才解决方案。",
        //内容
        firstTitle: "强大的人才数据库",
        firstContent: "我们的平台上有超过600k+的软件行业人才候选人。从初级（1-3年经验）到来自主要科技巨头的高级经验（5-10年经验）软件工程师，具有成熟的技能和业绩记录。我们以最合理的成本拥有最大的软件开发人才数据库，可满足您对任何规模、预算和时间表的项目的需求。以任何预算开始您的项目！注册时可获得 100 美元的免费咨询和试用积分！",
        secondTitle: "提供安全满意的服务",
        secondContent: "我们基于项目的定价、项目投标和审查机制可确保您的项目以最高的满意度交付。您的存款保存在我们的平台上，仅在项目交付和满意时支付给软件工程师。如果因任何原因不满意，将进行更改或错误修复，直到完全满意或退款！（*退款将根据项目完成里程碑进行评估）您的初始要求只是高层次，缺乏详细要求？不用担心！我们的软件工程师将与您一起逐步提供指导和建议，并免费进行更改或设计调整！",
        thirdTitle: "协助管理您的项目",
        thirdContent: "我们将指派专门的项目经理协助管理您的项目，无需额外费用！项目经理将引导对话，规划开发范围，并与您一起完成每一步。",
        fourthTitle: "在24小时内为您匹配工程师",
        fourthContent: "请在此处发布您的高级要求。说明项目描述，所需的技术堆栈和您的预算，我们的项目经理将在24小时内与您联系，并根据我们的招标和审查系统提供匹配的软件工程师名单。",
    },

    service: {
        //标题
        serviceTitle: "我们的服务",
        serviceContent_1: "我们将指派专门的产品经理协助收集需求、时间表估算和付款",
        serviceContent_2: "我们的专业产品经理将利用最佳实践，通过实现全球团队的优势，同时使用现代工具和技术来确保有效的沟通，从而实现最大的协作效率。",
        serviceButton: "Read More",
        //内容
        firstTitle: "没有超出预算的头痛！",
        secondTitle: "您的要求和产品将完全保密，受法律保护。",
        thirdTitle: "基于项目的招聘 - 没有员工管理开销。",
        fourthTitle: "项目完成后免费提供6个月维护保证 6个月后合理的维护计划费用。",
        fifthTitle: "合理灵活的定价",
        sixthTitle: "最大的人才数据库确保精确匹配您所需的技能。",
        seventhTitle: "高质量和高效的编码实践。",
        eighthTitle: "项目进度的完全透明和可见性，专门的项目经理协助协调，沟通和管理。",
    },

    counter: {
        firstTitle: "良好的评价",
        secondTitle: "熟练的工程师",
        thirdTitle: "成功的项目",
    },

    portfolio: {
        //标题
        portfolioTitle: "主要服务",
        portfolioContent: "虽然我们的人才涵盖所有行业和技术，但以下是我们的一些专业领域。",
        //内容
        firstTitle: "金融科技-报告",
        firstContent: "通过专业的财务报告和风险管理咨询最大限度地提高您的财务绩效。",
        secondTitle: "云服务",
        secondContent: "通过专家咨询服务释放云计算的全部潜力。",
        thirdTitle: "网络爬虫",
        thirdContent: "通过专家网络爬虫咨询让您的网络数据触手可及。",
        fourthTitle: "商业智能",
        fourthContent: "通过专家商业智能咨询和定制培训释放数据的力量。",
        fifthTitle: "Chat-GPT",
        fifthConetnt: "助力您的AI之旅：量身定制的解决方案、专家指导、卓越的结果。",
    },

    testimonial: {
        //标题
        testimonialTitle: "我们的客户评价",
        testimonialContent: "确保最高水平的客户满意度始终是我们的首要目标。请随时在这里分享您的经验，我们将始终寻求改善服务的方法。",
        //内容
        title: "忠实客户",
        firstContent: "开发人员非常有共鸣且注重细节，确保彻底理解需求并提供准确的时间表估计。他们还努力通过使用最新的技术产品来确保技术堆栈是面向未来的。非常满意。",
        secondContent: "作为一家初创公司，我们有非常简单的SPA架构，我们在Taccelerator上聘请的工程师为我们提供了有关如何扩展和扩展服务的好主意，一些建议帮助我们节省了大量的开发成本，我可以感觉到他们真正关心他们的客户，并旨在与我们建立长期关系。我们建议任何初创公司尝试Taccelerator提供的按需就业开发模式，这被证明可以节省大量成本和时间。谢谢Taccelerator!",
    },

    homeblog: {
        blogTitle: "最近的博客",
        blogContent: "我们关于技术和行业更新的谈话。",
        blogType: "新闻",
        blogButton: "阅读更多",
    },

    footer: {
        user: "USER",
        login: "登录",//登录
        register: "注册",

        browse: "BROWSE",
        home: "主页",//主页
        findTalents: "找人才",//找人才
        pastProjects: "案例列表",//案例列表
        postedProjects: "接任务",//接任务
        reviews: "好评人才",//好评人才
        blogs: "博客",//博客
        contactUs: "联系我们",//联系我们

        mainService: "MAIN SERVICE",//主要服务
        financialTechnologyReporting: "金融科技 - 报告",//金融科技-报告
        cloudServices: "云服务",//云服务
        webCraw: "网络爬虫",//网络爬虫
        businessInteligence: "商业智能",//商业智能
        chatGPT: "Chat-GPT",//Chat-GPT
    },

    //<!-- investor-area-start -->
    investor: {
        title: {
            name: "联系我们",
            home: "主页",
            links: "联系我们",
        },
        content: {
            title: "编程协作的未来",
            content_1: "我们很自豪地推出一种新的编程协作模式，它将彻底改变软件开发的方式。我们的平台为开发人员和团队在处理复杂项目时面临的挑战提供了独特而创新的解决方案。",
            content_2: "我们的平台旨在汇集编程领域的最优秀人才，并提供一个协作空间，他们可以在其中共同构建创新的软件解决方案。通过利用最新的技术和工具，我们使团队能够更轻松、更高效地协同工作，无论其位置和时区如何。",
            content_3: "我们的平台不仅仅是一种协作工具，更是一种思考软件开发的新方式。我们相信协作是释放软件开发全部潜力的关键，我们的平台旨在实现这一目标。我们正在寻求投资，以帮助我们将这一愿景变为现实。我们的团队由软件开发、技术和创业方面的专家组成，我们对如何利用投资将我们的平台推向市场有一个明确的计划。",
            content_4: "投资我们的平台不仅仅是一个支持有前途的新创业公司的机会，也是一个成为塑造软件开发未来的一部分的机会。我们相信，我们的平台有潜力改变行业，为软件开发方式带来新的效率和创造力。",
            abstract: "因此，如果您正在寻找有可能改变世界的投资机会，我们邀请您加入我们，共同塑造编程协作的未来。立即联系我们，详细了解我们的平台以及如何成为其中的一部分。",
        },
        message: {
            title_1: "我们的办公室",
            title_2: "联系方式",
            title_3: "存在问题？请随时与我们联系！",
            call: "致电我们",
            mail: "致电我们",
            title_4: "我们乐于听取您的意见",
            title_5: "请致电或发送电子邮件联系表格，我们将很乐意为您提供帮助。",
            button: "发送信息",
        },
        modal: {
            title: "提示",
            hint_1: "姓名是必要的",
            hint_2: "邮箱是必要的",
            hint_3: "电话是必要的",
            hint_4: "信息内容是必要的",
            hint_5: "您要确认发送吗?",
            button_1: "关闭",
            button_2: "发送信息",
        }
    },

    blog: {
        title: {
            name: "博客",
            home: "主页",
            links: "博客",
        },
        button: {
            type: "新闻",
            readMore: "点击阅读",
            previous: "上一页",
            next: "下一页",
            search: "搜索",
        },
        sidebar: {
            popularPosts: "热门博客",
            categories: "类别",
            tag_1: "金融科技 - 报告",
            tag_2: "云服务",
            tag_3: "网络爬虫",
            tag_4: "商业智能",
            tag_5: "Chat - GPT",
        }
    },

    blogdetail: {
        title: {
            home: "主页",
            links: "博客",
        },
        sidebar: {
            search: "搜索",
            popularPosts: "热门博客",
            categories: "类别",
            tag_1: "金融科技 - 报告",
            tag_2: "云服务",
            tag_3: "网络爬虫",
            tag_4: "商业智能",
            tag_5: "Chat - GPT",
        }
    },

    cloudservice: [
        { title: "云战略制定", content: '制定成功的云战略意味着规划一条利用云技术全部潜力的道路，使组织能够达到新的成功高度。', expanded: false },
        { title: "云迁移", content: '迁移到云涉及仔细规划和执行一个复杂的过程，该过程需要将应用程序、数据和基础结构从本地环境迁移到云，同时最大限度地减少中断并确保最佳性能和成本效益。', expanded: false },
        { title: "云架构设计", content: "为构建可扩展、安全且经济高效的基础架构创建蓝图，以支持组织的需求和目标。", expanded: false },
        { title: "云成本优化", content: "优化云成本需要持续监控和分析云使用情况，确定节省成本的机会，实施经济高效的解决方案，并保持对云支出的可见性和控制，以便从云投资中获得最大价值，同时最大限度地减少浪费和低效率。", expanded: false },
        { title: "云安全性和合规性", content: "在云中保护和确保合规性需要一种全面的方法，包括实施最佳实践、利用尖端技术以及保持持续警惕以保护敏感数据和资产。", expanded: false },
        { title: "自定义支持", content: "我们量身定制的培训和支持服务旨在与您的特定业务流程和架构保持一致，为您的组织提供最大的效率和价值。此外，当您入职超过五名常规顾问时，我们会提供免费的定制培训，让他们为自己的角色做好准备，并使他们能够在加入您的团队后立即开始做出贡献。这有助于最大限度地减少与培训相关的内部资源和成本，并确保新员工的无缝过渡。", expanded: false },
    ],

    cloudservice_content: {
        title: "云服务",
        home: "主页",
        links: "Main Service",
        whoWeAre: "我们是谁",
        text_1: "是时候通过云计算将您的业务提升到一个新的水平了。云不再是未来，而是现在，好处显而易见：提高效率、节省成本和可扩展性。但是，切换到云可能会让人不知所措且复杂。这就是我们的云计算咨询服务的用武之地。",
        text_2: "我们的专家团队将与您合作，了解您的业务需求，并根据您的需求制定量身定制的云策略。无论您是刚刚起步还是希望优化现有的云基础架构，我们的咨询服务都将帮助您实现这一目标。",
        text_3: "通过专家咨询服务释放云计算的全部潜力",
        text_4: "凭借我们丰富的经验，我们拥有丰富的知识和专业知识，可帮助您驾驭云的复杂性，并确保您的过渡无缝且成功。我们致力于为客户提供最高水平的服务，我们将与您合作的每一步，以确保您的云计算之旅取得成功。",
        text_5: "根据您的特定行业和需求，专家将接受培训，以应用符合您需求的最佳行业标准和实践。",
        text_6: "不要满足于一刀切的云计算方法。与我们合作，释放云的全部潜力。立即与我们联系，详细了解我们的云计算咨询服务以及我们如何为您提供帮助。",
    },

    financialreporting: [
        { title: "财务报告流程改进", content: "改进财务报告流程涉及确定需要优化的领域、简化工作流程、实施最佳实践、利用尖端技术以及加强团队之间的协作，以加快报告周期、提高准确性并更好地了解财务绩效。", expanded: false },
        { title: "财务风险评估", content: "识别和评估可能影响组织财务健康的潜在风险，例如市场波动、信用风险、运营风险和流动性风险，并制定风险缓解策略和应急计划，以确保组织处于有利地位，以应对潜在威胁并实现其财务目标。", expanded: false },
        { title: "法规遵从性咨询", content: "驾驭复杂的法规遵从性环境需要一种全面的方法，包括评估合规性要求、制定和实施合规性计划、进行审计和风险评估，以及提供持续的指导和支持，以帮助组织掌握法规变化并避免代价高昂的处罚和声誉损害。", expanded: false },
        { title: "数据管理和报告自动化", content: "简化和优化数据管理流程，包括数据集成、数据质量和数据治理，并使用尖端技术自动化报告流程，以确保数据准确、最新且随时可用，并确保快速高效地生成报告，使组织能够自信地做出数据驱动的决策。", expanded: false },
        { title: "商业智能和数据可视化", content: "通过 BI 和数据可视化释放数据的全部潜力涉及利用尖端工具和技术来创建令人惊叹的交互式可视化，使数据栩栩如生，使用户能够提取有意义的见解并推动明智的决策，使组织能够实现新的成功水平。", expanded: false },
        { title: "金融风险管理", content: "识别、评估组织面临的潜在财务风险并确定其优先级，例如信用风险、市场风险、运营风险和流动性风险，并制定减轻这些风险的策略，包括风险转移、风险规避、风险降低和风险接受，以确保组织处于有利地位，以驾驭不确定的金融环境并实现其战略目标。", expanded: false },
    ],

    financialreporting_content: {
        title: "金融科技 - 报告",
        home: "主页",
        links: "Main Service",
        whoWeAre: "我们是谁",
        text_1: "凭借在 IT 行业 10 年的经验，是时候通过专业的财务报告和风险管理咨询服务将您的财务业绩提升到一个新的水平了。准确的财务报告和有效的风险管理是任何成功企业的关键组成部分，但它们可能既复杂又耗时。这就是我们的财务报告和风险管理咨询服务的用武之地。",
        text_2: "我们的专家团队将与您合作，了解您的业务需求，并根据您的要求制定量身定制的财务报告和风险管理策略。无论您是希望改进财务报告流程，还是需要更有效地管理风险，我们的财务报告和风险管理咨询服务都将帮助您实现这一目标。",
        text_3: "通过专业的财务报告和风险管理咨询最大限度地提高您的财务绩效",
        text_4: "凭借我们丰富的经验，我们拥有丰富的知识和专业知识，可帮助您应对复杂的财务报告和风险管理，并确保您的财务业绩得到优化。我们致力于为客户提供最高水平的服务，我们将与您合作的每一步，以确保您的财务报告和风险管理之旅取得成功。",
        text_5: "与我们合作，通过专业的财务报告和风险管理咨询，最大限度地提高您的财务业绩。立即与我们联系，详细了解我们的财务报告和风险管理咨询服务以及我们如何为您提供帮助。",
    },

    webcrawl: [
        { title: "网络爬虫策略开发", content: "设计有针对性的方法，系统有效地从互联网收集数据，利用尖端工具和技术从大量非结构化数据中提取见解，并优化网络爬网过程以确保数据的准确性、相关性和及时性，使组织能够做出明智的决策并获得竞争优势。", expanded: false },
        { title: "网络爬网体系结构设计", content: "创建一个可扩展且高效的系统，用于收集和分析来自互联网的数据，利用尖端技术和工具来优化数据提取、存储和处理，同时确保数据质量并符合法律和道德标准，使组织能够获得有价值的见解，并在快速发展的数字环境中保持竞争优势。", expanded: false },
        { title: "网络爬网和自动化", content: "利用网络爬行和自动化的力量涉及自动化重复性任务和流程以节省时间和提高效率，同时还从互联网收集有价值的数据，使用尖端的网络抓取工具和技术从大量非结构化数据中提取见解，并将这些数据转化为可操作的见解，使组织能够获得竞争优势并以更快的速度和准确性实现其目标。", expanded: false },
        { title: "数据处理和分析", content: "将原始数据转化为有意义的见解，使用尖端工具和技术来清理、构建和丰富数据，并应用高级分析和统计方法来发现隐藏的模式、趋势和相关性，使组织能够做出明智的决策并以更高的精度和准确性实现其战略目标。", expanded: false },
        { title: "数据可视化和报告", content: "使用尖端技术和工具将复杂数据转换为清晰、直观的可视化和报告，为利益相关者提供可操作的见解并推动明智的决策，使组织能够识别趋势、模式和机会，并优化其绩效，以更高的效率和有效性实现其目标。", expanded: false },
        { title: "自定义支持", content: "我们为我们的网络爬虫解决方案提供全面支持和保证，并可选择延长 2、5、10 年的保修期。任何系统的网站更新都将包含在我们的保证范围内，并确保 24 小时承诺恢复任何更改的网络爬虫。", expanded: false },
    ],

    webcrawl_content: {
        title: "网络爬虫",
        home: "home",
        links: "Main Service",
        whoWeAre: "我们是谁",
        text_1: "凭借在 IT 行业 10 年的经验，是时候通过专业的网络爬虫咨询服务来利用 Web 数据的力量了。网络上可用的大量信息是无与伦比的，但收集和理解它可能是压倒性的和具有挑战性的。这就是我们的网络爬虫咨询服务的用武之地。",
        text_2: "我们的专家团队将与您合作，了解您的数据需求，并根据您的要求制定量身定制的网络爬虫策略。无论您是想为市场研究、潜在客户生成还是任何其他目的收集数据，我们的网络爬虫咨询服务都将帮助您实现目标。",
        text_3: "通过专家网络爬虫咨询让您的网络数据触手可及",
        text_4: "凭借我们丰富的经验，我们拥有丰富的知识和专业知识，可帮助您驾驭复杂的网络数据，并确保您的数据收集高效且有效。我们致力于为客户提供最高水平的服务，我们将与您合作的每一步，以确保您的网络爬虫之旅取得成功。",
        text_5: "不要满足于不完整或不准确的数据。与我们合作，让您的网络数据触手可及。立即与我们联系，以了解有关我们的网络爬虫咨询服务以及我们如何为您提供帮助的更多信息。",
    },

    businessintelligence: [
        { title: "商业智能战略制定", content: "设计用于收集、分析和利用数据以推动业务绩效的全面计划，使用尖端工具和技术创建可操作的见解并赋予决策者权力，并使数据和分析功能与业务目标和优先级保持一致，使组织能够做出明智的决策并以更快的速度和准确性实现其战略目标。", expanded: false },
        { title: "数据管理和治理", content: "确保整个组织内数据的质量、安全性和可访问性，实施策略和程序以确保符合法规要求和最佳实践，并利用尖端工具和技术提高效率并实现跨团队协作，使组织能够自信地做出明智的决策。", expanded: false },
        { title: "数据仓库和集成", content: "使用尖端技术和工具将来自多个来源的数据整合并集成到集中式存储库中，以确保数据质量、一致性和可访问性，并使组织能够根据单一、可靠的事实来源获得见解并做出明智的决策，从而简化工作流程并提高效率，同时减少错误和重复工作。", expanded: false },
        { title: "数据分析和可视化", content: "使用先进的统计和分析技术从大型复杂数据集中提取见解，并创建直观且引人注目的可视化，以帮助用户轻松识别趋势、模式和关系，使组织能够做出数据驱动的决策并在其行业中获得竞争优势。", expanded: false },
        { title: "仪表板设计和开发", content: "使用尖端工具和技术为复杂数据创建引人注目的直观可视化表示，使用户能够轻松快速地分析和理解关键见解，使组织能够做出明智的决策并及时采取行动。", expanded: false },
        { title: "为您的团队定制培训", content: "我们量身定制的培训和支持服务旨在与您的特定业务流程和架构保持一致，为您的组织提供最大的效率和价值。此外，当您入职超过五名常规顾问时，我们会提供免费的定制培训，让他们为自己的角色做好准备，并使他们能够在加入您的团队后立即开始做出贡献。这有助于最大限度地减少与培训相关的内部资源和成本，并确保新员工的无缝过渡。", expanded: false },
    ],

    businessintelligence_content: {
        title: "商业智能",
        home: "home",
        links: "Main Service",
        whoWeAre: "我们是谁",
        text_1: "凭借在 IT 行业 10 年的经验，是时候通过专家商业智能咨询和定制培训服务来释放数据的力量了。商业智能已成为成功企业的重要组成部分，帮助组织根据数据驱动的见解做出明智的决策。然而，驾驭复杂的商业智能世界可能具有挑战性。这就是我们的商业智能咨询和定制培训服务的用武之地。",
        text_2: "我们的专家团队将与您合作，了解您的业务需求，并根据您的要求制定量身定制的商业智能策略。无论您是希望改进数据管理流程，还是需要从数据中获得见解，我们的商业智能咨询服务都将帮助您实现这一目标。",
        text_3: "通过专家商业智能咨询和定制培训释放数据的力量",
        text_4: "我们相信使我们的客户能够充分利用他们的商业智能解决方案，这就是为什么我们为派去服务我们的客户的专家提供定制培训。此培训将确保您的团队具备有效利用我们实施的商业智能工具和流程所需的知识和技能。",
        text_5: "凭借我们丰富的经验，我们拥有丰富的知识和专业知识，可帮助您驾驭商业智能的复杂性，并确保您的数据驱动型决策得到优化。我们致力于为客户提供最高水平的服务，我们将与您合作，确保您的商业智能之旅取得成功。",
        text_6: "不要满足于低于标准的数据驱动决策。与我们合作，通过专家商业智能咨询和",
    },

    GPT: [
        { title: "模型定制", title_1: "量身定制的 AI 解决方案：释放特定领域 GPT 模型的力量", content: "通过我们的专家模型定制服务释放 GPT 模型的全部潜力。我们根据您的独特领域或行业调整和微调 AI 解决方案，确保无缝集成和最大相关性。受益于我们的专业知识，使您的组织能够理解特定领域的语言，提高生产力并推动创新。", expanded: false },
        { title: "部署策略", title_1: "无缝 AI 集成：有效部署 GPT 模型的专家指导", content: "借助我们的专家部署策略服务，自信地驾驭 GPT 模型部署的复杂环境。我们协助您将 GPT 模型集成到现有系统中，最大限度地提高效率和有效性。受益于我们在基于云的、本地和边缘计算解决方案方面的经验，以释放组织中 AI 的全部潜力。", expanded: false },
        { title: "安全性与合规性", title_1: "保护您的 AI 之旅：GPT 模型的强大安全性和合规性", content: "相信我们的安全与合规服务可以保护组织的 AI 投资。我们优先考虑数据隐私、安全性和法规遵从性，确保您的 GPT 模型部署符合严格的行业标准。在我们的专家指导下，您可以放心地踏上您的 AI 之旅，因为您知道您的数据和系统是安全的。", expanded: false },
        { title: "优化性能", title_1: "最大化 AI 效率：优化 GPT 模型性能以获得卓越的结果", content: "通过我们的性能优化服务体验差异，我们微调 GPT 模型以提供最佳的用户体验和运营效率。我们的专业知识使您的组织能够优化响应时间、吞吐量和计算要求，确保您的 AI 解决方案发挥最佳性能，推动业务成功。", expanded: false },
        { title: "对话设计", title_1: "转变交互：用于引人入胜的 AI 体验的专家对话设计", content: "通过我们的对话设计服务提升您的客户互动。我们在用户体验设计和自然语言处理方面的专业知识可帮助您为聊天机器人和虚拟助手创建引人入胜且有效的对话流程。借助 AI 驱动的解决方案，实现客户支持、销售和 HR 流程的转型，从而增强沟通并简化运营。", expanded: false },
        { title: "评估和分析", title_1: "成功的 AI 洞察：GPT 模型的综合评估和分析", content: "通过我们的评估和分析服务释放您的 AI 投资的全部价值。我们实施正确的评估指标和分析工具，使您的组织能够跟踪、优化和提升 GPT 模型的性能。做出数据驱动的决策并推动持续改进，确保 AI 驱动的工作取得长期成功。", expanded: false },
    ],

    GPT_content: {
        title: "Chat - GPT",
        home: "主页",
        links: "Main Service",
        whoWeAre: "我们是谁",
        text_1: "凭借其庞大的知识库和先进的语言处理能力，ChatGPT 已准备好通过专家自然语言处理咨询和定制培训服务来释放语言的力量。自然语言处理已成为成功企业的重要组成部分，通过机器学习和其他技术帮助组织理解和分析基于文本的数据。然而，驾驭自然语言处理的复杂世界可能具有挑战性。这就是我们的咨询和培训服务的用武之地。",
        text_2: "驾驭复杂的通信和信息交流世界可能具有挑战性，但通过我们定制的培训和咨询服务，ChatGPT 可以帮助您克服这些障碍。我们的专家团队将与您合作，了解您的沟通需求，并根据您的要求制定量身定制的策略。无论您是需要提高写作技巧还是想自动化客户支持流程，我们的 ChatGPT 咨询和培训服务都将帮助您实现目标。",
        text_3: "助力您的 AI 之旅：量身定制的解决方案、专家指导、卓越的结果",
        text_4: "在 ChatGPT，我们致力于使我们的客户能够充分利用我们的语言处理能力。这就是为什么我们为专家提供定制培训，他们将与您的团队合作，以确保他们拥有有效使用 ChatGPT 所需的知识和技能。",
        text_5: "凭借我们丰富的经验和专业知识，我们可以帮助您驾驭沟通和信息交流的复杂性，并确保您充分利用 ChatGPT 的功能。我们致力于为客户提供最高水平的服务，我们将与您合作的每一步，以确保满足您的沟通和信息交换需求。",
        text_6: "不要满足于低于标准的沟通和信息交流。与我们合作，通过专家咨询和定制培训服务释放 ChatGPT 的力量。",
    },
    //<!-- Posted-Projects-start -->
    PostedProjects: {
        Homepage: {
            title: "申请当前发布的项目",
            description: "我们目前的项目列表正在寻找人才。请只申请适合您的技能和经验的项目。",
            side_title_1: "发布任务",
            side_description_1: "招聘专员1小时内与您联系，1小时内准确识别人才",
            side_button_1: "发布任务",
            side_title_2: "更新您的个人资料",
            side_description_2: "完整的工程师人才档案将确保您获得最佳的任务匹配结果，并更多地接触雇主。",
            side_button_2: "更新个人资料",

            button_1: "上一页",
            button_2: "下一页",
        },
        Task_detail: {
            title_1: "人才类型：",
            title_2: "预估工时：",
            title_3: "总预算 ：",
            title_4: "项目介绍：",
            button_1: "立即申请",
            button_2: "已申请",
            description_1: "人已申请"
        },
        PromptBox: {
            title: "提交我的申请",
            content: "我们会将您的个人资料发送给该雇主。请确保您的技能与任务描述相匹配，并且每日工资在您的预期范围内。一旦雇主决定，雇主将直接与您联系或通过我们与您联系。请留意“我的中心”页面中的收件箱。",
            button_1: "关闭",
            button_2: "确认",
        },
    },

    PostedProjectsDetail: {
        Homepage: {
            title_1: "预估日薪：",
            title_2: "预计总价：",
            title_3: "预计工时：",
            title_4: "下载附件",
            title_5: "需求描述",

            side_title_1: "发布任务",
            side_description_1: "招聘专员1小时内与您联系，1小时内准确识别人才",
            side_button_1: "发布任务",
            side_title_2: "更新您的个人资料",
            side_description_2: "完整的工程师人才档案将确保您获得最佳的任务匹配结果，并更多地接触雇主。",
            side_button_2: "更新个人资料",

            button_1: "立即申请",
        },
        PromptBox: {
            title: "确认申请",
            content: "确保您的技能符合您的需求，并且您的每日工资在可接受的范围内。匹配成功后，平台和雇主将与您联系。",
            button_1: "关闭",
            button_2: "确认",
        },
    },

    //<!-- Past-Projects-start -->
    PastProjects: {
        Homepage: {
            title: "查看成功案例",
            description: "我们的平台上有超过10，000个成功案例。在这里，您可以找到适合或类似于您的项目的示例",
            
            side_title_1: "发布任务",
            side_description_1: "招聘专员1小时内与您联系，1小时内准确识别人才",
            side_button_1: "发布任务",
            side_title_2: "更新您的个人资料",
            side_description_2: "完整的工程师人才档案将确保您获得最佳的任务匹配结果，并更多地接触雇主。",
            side_button_2: "更新个人资料",

            button_1: "上一页",
            button_2: "下一页",
        },
        Detail: {
            title_1: "项目类型",
            title_2: "项目介绍"
        },
    },

    //<!-- Reviews-start -->
    Reviews: {
        title: "客户评论",
        description: "",
        title_1: "订单数量: ",
        title_2: "工作经验: ",

        side_title_1: "发布任务",
        side_description_1: "招聘专员1小时内与您联系，1小时内准确识别人才",
        side_button_1: "发布任务",
        side_title_2: "更新您的个人资料",
        side_description_2: "完整的工程师人才档案将确保您获得最佳的任务匹配结果，并更多地接触雇主。",
        side_button_2: "更新个人资料",

        button_1: "上一页",
        button_2: "下一页",
    },

    //<!-- Login-Register-start -->
    Login: {
        title_1: "请登录",
        title_2: "同意条款和条件",
        title_3: "没有Taccelerator帐户?",
        email: "电子邮箱",
        password: "密码",
        button_1: "登录",
        button_2: "注册",
        Tips: {
            tip_email: "电子邮件地址为必填项",
            tip_password_1: "密码为必填项",
            tip_password_2: "密码或帐号不正确",
            tip_terms: "您必须在提交之前同意",
        }
    },

    Register_identify: {
        title: "以雇主或自由职业者身份加入",
        client: "雇主",
        freelancer: "FREELANCER",
        client_description_1: "我是雇主",
        client_description_2: "寻求帮助",
        client_description_3: "以完成我的项目",
        freelancer_description_1: "我是一名自由职业者",
        freelancer_description_2: "我在找符合",
        freelancer_description_3: "我期望的工作",
        button: "注册",
    },

    Register: {
        title_1: "注册以获得你所需要的",
        title_2: "已经有一个帐户？",
        title_3: "同意条款和条件",
        firstname: "名称",
        lastname: "姓",
        email: "电子邮箱",
        code: "验证码",
        password: "密码",
        select: "选择区域",
        select_1: "China",
        select_2: "Canada",
        button: "创建我的账户",
        link: "登录",
        Tips: {
            tip_fristname: "名字为必填项",
            tip_lastname: "姓氏为必填项",
            tip_name: "抱歉！没有特殊字符或数字",
            tip_email: "电子邮箱已被注册",
            tip_email_1: "电子邮件地址为必填项",
            tip_email_2: "邮件格式不正确",
            tip_password_1: "密码为必填项",
            tip_password_2: "密码安全强度级别太低",
            tip_area: "请选择您所在的地区",
            tip_terms: "您必须在提交之前同意",
            tip_code: "请输入验证码",
        }
    },

    Register_freelancer: {
        step: {
            title_1: "让我们花5-10分钟来介绍自己！",
            title_2: "我们需要了解您的经验和技能，以及一些必要的个人信息。",
            title_3: "你的自我介绍能让你从人群中脱颖而出。这是你赢得工作的手段，所以让我们一起把它做好。",
            title_4: "-- 来自Taccelerator的建议",
            button_1: "填写",
            button_2: "返回",
            button_3: "提交",
        },
        step1: {
            title_1: "现在添加您的职业名称，让每个人都知道您在做什么。",
            title_2: "We need to know your experience and skills, and some necessary personal information.",
            tip: "至少需要4个字符",
        },
        step2: {
            title_1: "现在告诉我们您的技能和能力。",
            title_2: "您的技能是否向客户展示您必须提供的内容，并让我们知道向您推荐哪些工作。",
            title_3: "你可以描述你的技能和相关优势，这将使你对雇主更具吸引力。",
            title_4: "至少 100 个字符",
            tip: "技术和技能的介绍是必填项",
        },
        step3: {
            title_1: "现在告诉我们您的项目经验。",
            title_2: "有经验的工作者更有可能获得客户的信任，因此我们可以尽可能好地写出来。",
            title_3: "您可以描述您参与的项目的需求、实现结果和相关技术。",
            title_4: "至少 100 个字符",
            tip: "项目经验是必填项",
        },
        step4: {
            title_1: "客户总是会寻找与自己项目类似的案例。",
            title_2: "客户总是会寻找与自己项目类似的案例。",
            title_3: "你可以描述项目的需求、人才需求、参考产品以及如何合作。",
        },
        step5: {
            title_1: "填写您以前或现在的雇主以及您的整个工作生涯。",
            title_2: "这是必需的，客户可能对特定企业的员工或员工工作的时间长短感兴趣。",
            title_3: "以前的公司",
            title_4: "工作经验",
            tip_1: "填写您的单位",
            tip_2: "填写您的工作经验"
        },
        step6: {
            title_1: "最后几个细节，我们快完成了。",
            title_2: "不用担心。我们对这些信息保密。只有你可以看到它。",
            title_3: "街道地址",
            title_4: "城市",
            title_5: "州/省",
            title_6: "邮编",
            title_7: "电话号码",
            tip_1: "填写您的街道地址",
            tip_2: "请提供有效的城市。",
            tip_3: "请选择一个有效的州",
            tip_4: "请提供有效的邮政编码",
            tip_5: "填写您的电话号码",
        },
    },
}