export default {
    //<!-- home-area-start -->
    //导航栏
    navigation: {
        home: "Home",//主页
        findTalents: "Find Talents",//找人才
        pastProjects: "Past Projects",//案例列表
        postedProjects: "Posted Projects",//接任务
        reviews: "Reviews",//好评人才
        blogs: "Blogs",//博客
        contactUs: "Contact us",//联系我们

        mainService: "Main service",//主要服务
        financialTechnologyReporting: "Financial Technology - Reporting",//金融科技-报告
        cloudServices: "Cloud Services",//云服务
        webCraw: "Web Crawl",//网络爬虫
        businessInteligence: "Business Inteligence",//商业智能
        chatGPT: "Chat-GPT",//Chat-GPT

        search: "Search",//搜索
        login: "Login",//登录

        myCenter: "My Center",//个人中心
        homePage: "Home Page",//个人主页
        loginOut: "Login Out",//退出

        register: "Register",//注册
    },

    //主页轮播
    wheelSeeding: {
        firstTitle: "Global talent coverage",
        firstContent: "Global Talent Reach - Hire Software Developers, QA Engineers, and Product Managers with Project-based hiring and payment.",
        firstButton: "SIGN UP TODAY",
        secondTitle: "Plenty of talent",
        secondContent: "Over 30k+ Candidates available, fits all your project needs. Success and satisfaction guaranteed.",
        secondButton: "LEARN MORE",
        thirdTitle: "A large number of excellent cases",
        thirdContent: "There are more than 40,000 successful cases and more than 4,000 positive reviews",
        thirdButton: "BROWSE GALLERY",
    },

    //欢迎部分
    welcome: {
        targetTitle: "Our Target",
        targetContent: "We target to provide quality software development services, solutions, and talents for Canadian startups with sensible, flexible, and start- up friendly pricing.",
        visionTitle: "Our Vision",
        visionContent: "We aim to revolutionize the process of software development, collboration, and manangment with recent development in AI and Blockchain technologies.",
        valuesTitle: "Our Values",
        valuesContent: "Providing abundant talents in the most flexible and efficent ways to Canadian business to foster higher level of innovation, development, and collaborations.",
    },

    why: {
        //标题
        whyTitle: "Our Service And Commitments",
        whyContent: "Providing scalable and flexible talent solutions with innovative process and collaborations.",
        //内容
        firstTitle: "Powerful Talent Database",
        firstContent: "We have over 600k+ of software industry talents candidates on our platforms. Ranging from junior (1-3 yrs) to senior experienced (5-10 yrs) software engineers from major Tech Giants, with proven skills and track records. With the largest database of software development talent at the most reasonable cost, we cater to your needs for project of any size, budgets, and timelines. Starting your project at any budget! With $100 free consultation and trial credit when you sign up!",
        secondTitle: "Provide Safe And Satisfactory Service",
        secondContent: "Our project based pricing, project bidding and review mechanism ensures that your project are delivered at the highest satisfaction level. Your deposits are held at our platform and only paid out to the software engineers upon project delivery and satisfactions. If not satisfied for any reason, alternations or bug-fix will be made until full satisfactory or your money back! (*Refund will be evaluated by project completion milestones) Your initial requirements are only high level and lacks detailed requirement? No worries! Our software engineers will work with you step by step to provide guidance and suggestions, and make alterations or design adjustments at no extra costs!",
        thirdTitle: "Assist In Managing Your Project",
        thirdContent: "we will assign dedicated project manager to assist with managing your projects, at no extra costs! Project manager will lead the conversations, plan for development scopes, and work with you every step of the way.",
        fourthTitle: "Match You With An Engineer Within 24 Hours",
        fourthContent: "Please publish your high level requirement here. Stating the project description, desired technology stack, and your budget, our project manager will contact you within 24 hours and provide list of matched software engineers based on our bidding and review system.",
    },

    service: {
        //标题
        serviceTitle: "Our Services",
        serviceContent_1: "We will assign dedicated product managers to assist in requirement gathering, timeline estimate, and payments.",
        serviceContent_2: "Our dedicated product manager will utilize best practices to achieve maximum collaboration efficiency by realizing the benefit of a global team while using modern tools and technologies to ensure effective communication.",
        serviceButton: "Read More",
        //内容
        firstTitle: "No running over budget headache!",
        secondTitle: "Your requirements and product will be kept fully in confidential, protected by law.",
        thirdTitle: "Project based hiring - no employee management overheads.",
        fourthTitle: "Free 6 month maintenance guarantee after project completions sensible maintenance plan costs after 6 month.",
        fifthTitle: "Sensible and flexible pricing.",
        sixthTitle: "Largest database of talents ensure precise matching of the skills you needed.",
        seventhTitle: "High quality and efficient coding practices.",
        eighthTitle: "Full transparency and visibility of the project progress, dedicated project manager to assist in coordination, communication, and management.",
    },

    counter: {
        firstTitle: "Good Evaluation",
        secondTitle: "Skilled Engineers",
        thirdTitle: "Successful Projects",
    },

    portfolio: {
        //标题
        portfolioTitle: "Main service",
        portfolioContent: "While our talents covers all industries and technologies, here are some of our specialized areas.",
        //内容
        firstTitle: "Financial Technology - Reporting",
        firstContent: "Maximize Your Financial Performance with Expert Financial Reporting and Risk Management Consulting",
        secondTitle: "Cloud services",
        secondContent: "Unlock the Full Potential of Cloud Computing with Expert Consulting Services",
        thirdTitle: "Web Crawl",
        thirdContent: "Get Your Web Data at Your Fingertips with Expert Web Crawl Consulting",
        fourthTitle: "Business Inteligence",
        fourthContent: "Unlock the Power of Data with Expert Business Intelligence Consulting and Customized Training",
        fifthTitle: "Chat-GPT",
        fifthConetnt: "Empower Your AI Journey: Tailored Solutions, Expert Guidance, Superior Results",
    },

    testimonial: {
        //标题
        testimonialTitle: "Our Testimonials",
        testimonialContent: "Ensure the highest level of customer satisfaction is always our primary target. Feel free to share your experience here and we will always seek ways to improve our service.",
        //内容
        title: "Loyal Customer",
        firstContent: "The developers are very resonponsible and detail oriented, making sure understanding the requirements throughly and provides accurate timeline estimates. They also make an effort to ensure the techology stack is future proof by using the latest technogloy offerings. Very satisfied.",
        secondContent: "As a startup, we have very simplicitic SPA architeture, engineers we hired on Taccelerator offered us great ideas on how to expand and scale our services, some suggestions help saved us a lot of development costs, I can feel that they genuinely care about their customer and aim to develop long term relationship with us. We recommend any startups to try ths development mode of on-demand employment offered by Taccelerator which proves to be great cost and time saving. Thank you Taccelerator!",
    },

    homeblog: {
        //
        blogTitle: "Recent Blog",
        blogContent: "Our talks on tech and industry updates.",
        blogType: "News",
        blogButton: "Read More",
    },

    footer: {
        user: "USER",
        login: "Login",
        register: "Register",

        browse: "BROWSE",
        home: "Home",
        findTalents: "Find Talents",
        pastProjects: "Past Projects",//案例列表
        postedProjects: "Posted Projects",//接任务
        reviews: "Reviews",//好评人才
        blogs: "Blogs",//博客
        contactUs: "Contact us",//联系我们

        mainService: "MAIN SERVICE",//主要服务
        financialTechnologyReporting: "Financial Technology - Reporting",//金融科技-报告
        cloudServices: "Cloud Services",//云服务
        webCraw: "Web Crawl",//网络爬虫
        businessInteligence: "Business Inteligence",//商业智能
        chatGPT: "Chat-GPT",//Chat-GPT
    },

    //<!-- investor-area-start -->
    investor: {
        title: {
            name: "CONTACT US",
            home: "home",
            links: "Contact Us",
        },
        content: {
            title: "The Future of Programming Collaboration",
            content_1: " We are proud to introduce a new model of programming collaboration that is set to revolutionize the way software development is done.  Our platform offers a unique and innovative solution to the challenges faced by developers and teams when working on complex projects.",
            content_2: "Our platform is designed to bring together the best minds in programming and provide a collaborative space where they can work together to build innovative software solutions.  By leveraging the latest technologies and tools, we are making it easier and more efficient for teams to work together, regardless of location and time zone.",
            content_3: "Our platform is not just a tool for collaboration, but a new way of thinking about software development.  We believe that collaboration is the key to unlocking the full potential of software development, and our platform is designed to make that a reality. We are seeking investment to help us bring this vision to life.  Our team is made up of experts in software development, technology, and entrepreneurship, and we have a clear plan for how we will use investment to bring our platform to market.",
            content_4: "Investing in our platform is not just an opportunity to support a promising new startup, but a chance to be a part of shaping the future of software development.  We believe that our platform has the potential to transform the industry and bring a new level of efficiency and creativity to the way software is developed.",
            abstract: "So, if you're looking for an investment opportunity that has the potential to change the world, we invite you to join us in shaping the future of programming collaboration.  Contact us today to learn more about our platform and how you can be a part of it.",
        },
        message: {
            title_1: "Our Offices",
            title_2: "Contact Info",
            title_3: "Questions? Feel free to contact us!",
            call: "Call Us",
            mail: "Mail Us",
            title_4: "We Love to Hear From You",
            title_5: "Please call or email contact form and we will be happy to assist you.",
            button: "SEND MESSAGE",
        },
        modal: {
            title: "Submit information",
            hint_1: "Name is required",
            hint_2: "Email is required",
            hint_3: "Phone is required",
            hint_4: "Message is required",
            hint_5: "Do you want to confirm sending ?",
            button_1: "Close",
            button_2: "Send Message",
        }
    },

    //<!-- blog-area-start -->
    blog: {
        title: {
            name: "Blogs",
            home: "home",
            links: "Blogs",
        },
        button: {
            type: "News",
            readMore: "Read More",
            previous: "Previous",
            next: "Next",
            search: "Search",
        },
        sidebar: {
            popularPosts: "Popular Posts",
            categories: "Categories",
            tag_1: "Financial Technology - Reporting",
            tag_2: "Cloud Services",
            tag_3: "Web Crawl",
            tag_4: "Business Inteligence",
            tag_5: "Chat - GPT",
        }
    },

    blogdetail: {
        title: {
            home: "home",
            links: "Blogs",
        },
        sidebar: {
            search: "Search",
            popularPosts: "Popular Posts",
            categories: "Categories",
            tag_1: "Financial Technology - Reporting",
            tag_2: "Cloud Services",
            tag_3: "Web Crawl",
            tag_4: "Business Inteligence",
            tag_5: "Chat - GPT",
        }
    },

    cloudservice: [
        { title:"Cloud strategy development", content: 'Crafting a winning cloud strategy means charting a path to harness the full potential of cloud technologies, enabling organizations to reach new heights of success.', expanded: false },
        { title:"Cloud migrations", content: 'Migrating to the cloud involves carefully planning and executing a complex process that entails moving applications, data, and infrastructure from on-premises environments to the cloud, while minimizing disruption and ensuring optimal performance and cost-efficiency.', expanded: false },
        { title:"Cloud architecture design", content: "Creating a blueprint for building a scalable, secure, and cost-effective infrastructure that supports an organization's needs and objectives.", expanded: false },
        { title:"Cloud cost optimization", content: "Optimizing cloud costs entails continuously monitoring and analyzing cloud usage, identifying cost-saving opportunities, implementing cost-effective solutions, and maintaining visibility and control over cloud spending, in order to achieve maximum value from cloud investments while minimizing waste and inefficiencies.", expanded: false },
        { title:"Cloud security and compliance", content: "Securing and ensuring compliance in the cloud requires a comprehensive approach that includes implementing best practices, leveraging cutting-edge technologies, and maintaining constant vigilance to safeguard sensitive data and assets.", expanded: false },
        { title:"Custom Support", content: "Our tailored training and support services are designed to align with your specific business processes and architecture, delivering maximum efficiency and value to your organization. Additionally, when you onboard more than five regular consultants, we offer complimentary custom training to prepare them for their role and enable them to start contributing immediately upon joining your team. This helps to minimize internal resources and costs associated with training, and ensures a seamless transition for new hires.", expanded: false },
    ],

    cloudservice_content:{
        title:"Cloud services",
        home:"home",
        links:"Main Service",
        whoWeAre:"Who We Are",
        text_1:"It's time to take your business to the next level with cloud computing. The cloud is no longer the future, it's the present, and the benefits are clear: increased efficiency, cost savings, and scalability. However, making the switch to the cloud can be overwhelming and complex. That's where our cloud computing consulting services come in.",
        text_2:"Our team of experts will work with you to understand your business requirements and develop a cloud strategy that is tailored to your needs. Whether you're just starting out or looking to optimize your existing cloud infrastructure, our consulting services will help you get there.",
        text_3:"Unlock the Full Potential of Cloud Computing with Expert Consulting Services",
        text_4:"With our extensive experience, we have the knowledge and expertise to help you navigate the complexities of the cloud and ensure that your transition is seamless and successful. We're committed to providing our clients with the highest level of service, and we'll work with you every step of the way to ensure that your cloud computing journey is a success.",
        text_5:"Based on your specific industry and needs, the experts will be trained to apply the best indsutry standard and practices aligned with your needs.",
        text_6:"Don't settle for a one-size-fits-all approach to cloud computing. Partner with us and unlock the full potential of the cloud. Get in touch with us today to learn more about our cloud computing consulting services and how we can help you.",
    },

    financialreporting: [
        { title:"Financial reporting process improvement", content: "Improving the financial reporting process involves identifying areas for optimization, streamlining workflows, implementing best practices, leveraging cutting-edge technologies, and enhancing collaboration across teams, in order to accelerate reporting cycles, increase accuracy, and provide greater visibility into financial performance.", expanded: false },
        { title:"Financial risk assessment", content: "Identifying and evaluating potential risks that could impact an organization's financial health, such as market volatility, credit risk, operational risk, and liquidity risk, and developing risk mitigation strategies and contingency plans, to ensure that the organization is well-positioned to navigate potential threats and achieve its financial objectives.", expanded: false },
        { title:"Regulatory compliance consulting", content: "Navigating the complex landscape of regulatory compliance requires a comprehensive approach that includes assessing compliance requirements, developing and implementing compliance programs, conducting audits and risk assessments, and providing ongoing guidance and support, to help organizations stay on top of regulatory changes and avoid costly penalties and reputational damage.", expanded: false },
        { title:"Data management and reporting automation", content: "Streamlining and optimizing the data management process, including data integration, data quality, and data governance, and automating reporting processes using cutting-edge technologies, to ensure that data is accurate, up-to-date, and readily available, and that reports are generated quickly and efficiently, empowering organizations to make data-driven decisions with confidence.", expanded: false },
        { title:"Business intelligence and data visualization", content: "Unlocking the full potential of data through BI and data visualization involves leveraging cutting-edge tools and technologies to create stunning, interactive visualizations that bring data to life and enable users to extract meaningful insights and drive informed decisions, empowering organizations to achieve new levels of success.", expanded: false },
        { title:"Financial risk management", content: "Identifying, assessing, and prioritizing potential financial risks faced by an organization, such as credit risk, market risk, operational risk, and liquidity risk, and developing strategies to mitigate those risks, including risk transfer, risk avoidance, risk reduction, and risk acceptance, to ensure that the organization is well-positioned to navigate uncertain financial environments and achieve its strategic objectives.", expanded: false },
    ],

    financialreporting_content:{
        title:"Financial Technology - Reporting",
        home:"home",
        links:"Main Service",
        whoWeAre:"Who We Are",
        text_1:"With 10 years of experience in the IT industry, it's time to take your financial performance to the next level with expert financial reporting and risk management consulting services. Accurate financial reporting and effective risk management are critical components of any successful business, but they can be complex and time-consuming. That's where our financial reporting and risk management consulting services come in.",
        text_2:"Our team of experts will work with you to understand your business needs and develop a financial reporting and risk management strategy that is tailored to your requirements. Whether you're looking to improve your financial reporting processes, or you need to manage your risks more effectively, our financial reporting and risk management consulting services will help you get there.",
        text_3:"Maximize Your Financial Performance with Expert Financial Reporting and Risk Management Consulting",
        text_4:"With our extensive experience, we have the knowledge and expertise to help you navigate the complexities of financial reporting and risk management and ensure that your financial performance is optimized. We're committed to providing our clients with the highest level of service, and we'll work with you every step of the way to ensure that your financial reporting and risk management journey is a success.",
        text_5:"Partner with us and maximize your financial performance with expert financial reporting and risk management consulting. Get in touch with us today to learn more about our financial reporting and risk management consulting services and how we can help you.",
    },

    webcrawl: [
        { title:"Web crawl strategy development", content: "Designing a targeted approach to systematically and efficiently collect data from the internet, leveraging cutting-edge tools and technologies to extract insights from vast amounts of unstructured data, and optimizing the web crawl process to ensure data accuracy, relevance, and timeliness, empowering organizations to make informed decisions and gain a competitive edge.", expanded: false },
        { title:"Web crawl architecture design", content: "Creating a scalable and efficient system for collecting and analyzing data from the internet, leveraging cutting-edge technologies and tools to optimize data extraction, storage, and processing, while also ensuring data quality and compliance with legal and ethical standards, enabling organizations to gain valuable insights and stay ahead of the competition in the rapidly-evolving digital landscape.", expanded: false },
        { title:"Web crawl and automation", content: "Leveraging the power of web crawl and automation involves automating repetitive tasks and processes to save time and increase efficiency, while also collecting valuable data from the internet, using cutting-edge web scraping tools and techniques to extract insights from large amounts of unstructured data, and transforming that data into actionable insights, empowering organizations to gain a competitive advantage and achieve their goals with greater speed and accuracy.", expanded: false },
        { title:"Data processing and analysis", content: "Transforming raw data into meaningful insights, using cutting-edge tools and techniques to cleanse, structure, and enrich data, and applying advanced analytics and statistical methods to uncover hidden patterns, trends, and correlations, enabling organizations to make informed decisions and achieve their strategic goals with greater precision and accuracy.", expanded: false },
        { title:"Data visualization and reporting", content: "Transforming complex data into clear, intuitive visualizations and reports, using cutting-edge technologies and tools, to provide stakeholders with actionable insights and drive informed decision-making, enabling organizations to identify trends, patterns, and opportunities, and optimize their performance to achieve their goals with greater efficiency and effectiveness.", expanded: false },
        { title:"Custom Support", content: "We offer full support and guarrantee of our web crawl solutions, with option of extended gurrantee period of 2, 5, 10 years. Any systematic website update will be covered in our guarrantee and ensuring a 24-hour promise to recover the web crawl for any changes.", expanded: false },
    ],

    webcrawl_content:{
        title:"Web Crawl",
        home:"home",
        links:"Main Service",
        whoWeAre:"Who We Are",
        text_1:"With 10 years of experience in the IT industry, it's time to harness the power of web data with expert web crawl consulting services. The abundance of information available on the web is unmatched, but it can be overwhelming and challenging to gather and make sense of it. That's where our web crawl consulting services come in.",
        text_2:"Our team of experts will work with you to understand your data needs and develop a web crawl strategy that is tailored to your requirements. Whether you're looking to gather data for market research, lead generation, or any other purpose, our web crawl consulting services will help you get there.",
        text_3:"Get Your Web Data at Your Fingertips with Expert Web Crawl Consulting",
        text_4:"With our extensive experience, we have the knowledge and expertise to help you navigate the complexities of web data and ensure that your data collection is efficient and effective. We're committed to providing our clients with the highest level of service, and we'll work with you every step of the way to ensure that your web crawl journey is a success.",
        text_5:"Don't settle for incomplete or inaccurate data. Partner with us and get your web data at your fingertips. Get in touch with us today to learn more about our web crawl consulting services and how we can help you.",
    },

    businessintelligence: [
        { title:"Business intelligence strategy development", content: "Designing a comprehensive plan for collecting, analyzing, and leveraging data to drive business performance, using cutting-edge tools and technologies to create actionable insights and empower decision-makers, and aligning data and analytics capabilities with business objectives and priorities, to enable organizations to make informed decisions and achieve their strategic goals with greater speed and accuracy.", expanded: false },
        { title:"Data management and governance", content: "Ensuring the quality, security, and accessibility of data throughout the organization, implementing policies and procedures to ensure compliance with regulatory requirements and best practices, and leveraging cutting-edge tools and technologies to improve efficiency and enable collaboration across teams, enabling organizations to make informed decisions with confidence.", expanded: false },
        { title:"Data warehousing and integration", content: "Consolidating and integrating data from multiple sources into a centralized repository, using cutting-edge technologies and tools to ensure data quality, consistency, and accessibility, and enabling organizations to derive insights and make informed decisions based on a single, reliable source of truth, streamlining workflows and improving efficiency while reducing errors and duplication of effort.", expanded: false },
        { title:"Data analytics and visualization", content: "Using advanced statistical and analytical techniques to extract insights from large and complex data sets, and creating intuitive and compelling visualizations to help users easily identify trends, patterns, and relationships, enabling organizations to make data-driven decisions and gain a competitive advantage in their industry.", expanded: false },
        { title:"Dashboard design and development", content: "Creating compelling, intuitive visual representations of complex data, using cutting-edge tools and technologies to enable users to easily and quickly analyze and understand key insights, empowering organizations to make informed decisions and take timely action.", expanded: false },
        { title:"Customized training for your team", content: "Our tailored training and support services are designed to align with your specific business processes and architecture, delivering maximum efficiency and value to your organization. Additionally, when you onboard more than five regular consultants, we offer complimentary custom training to prepare them for their role and enable them to start contributing immediately upon joining your team. This helps to minimize internal resources and costs associated with training, and ensures a seamless transition for new hires.", expanded: false },
    ],

    businessintelligence_content:{
        title:"Business Inteligence",
        home:"home",
        links:"Main Service",
        whoWeAre:"Who We Are",
        text_1:"With 10 years of experience in the IT industry, it's time to unlock the power of data with expert business intelligence consulting and customized training services. Business intelligence has become a crucial component of successful businesses, helping organizations make informed decisions based on data-driven insights. However, navigating the complex world of business intelligence can be challenging. That's where our business intelligence consulting and customized training services come in.",
        text_2:"Our team of experts will work with you to understand your business needs and develop a business intelligence strategy that is tailored to your requirements. Whether you're looking to improve your data management processes, or you need to gain insights from your data, our business intelligence consulting services will help you get there.",
        text_3:"Unlock the Power of Data with Expert Business Intelligence Consulting and Customized Training",
        text_4:"We believe in empowering our clients to make the most of their business intelligence solutions, which is why we offer customized training to experts sent to service our clients. This training will ensure that your team has the knowledge and skills they need to effectively utilize the business intelligence tools and processes that we put in place.",
        text_5:"With our extensive experience, we have the knowledge and expertise to help you navigate the complexities of business intelligence and ensure that your data-driven decision-making is optimized. We're committed to providing our clients with the highest level of service, and we'll work with you every step of the way to ensure that your business intelligence journey is a success.",
        text_6:"Don't settle for subpar data-driven decision-making. Partner with us and unlock the power of data with expert business intelligence consulting and",
    },
    
    GPT: [
        { title:"Model Customization", content: "Unlock the full potential of GPT models with our expert Model Customization services. We adapt and fine-tune AI solutions to your unique domain or industry, ensuring seamless integration and maximum relevance. Benefit from our specialized expertise, which empowers your organization with domain-specific language understanding, boosting productivity and driving innovation.", expanded: false },
        { title:"Deployment Strategy", content: "Navigate the complex landscape of GPT model deployment with confidence, thanks to our expert Deployment Strategy services. We assist you in integrating GPT models within your existing systems, maximizing efficiency and effectiveness. Benefit from our experience in cloud-based, on-premises, and edge computing solutions to unlock the full potential of AI in your organization. ", expanded: false },
        { title:"Security and Compliance", content: "Trust our Security and Compliance services to protect your organization's AI investments. We prioritize data privacy, security, and regulatory compliance, ensuring that your GPT model deployment adheres to stringent industry standards. With our expert guidance, you can embark on your AI journey with confidence, knowing your data and systems are secure. ", expanded: false },
        { title:"Optimize Performance", content: "Experience the difference with our Performance Optimization services, where we fine-tune GPT models to deliver the best user experience and operational efficiency. Our specialized expertise enables your organization to optimizeresponse times, throughput, and computational requirements, ensuring that your AI solutions perform at their peak, driving business success.", expanded: false },
        { title:"Conversation Design", content: "Elevate your customer interactions with our Conversation Design services. Our expertise in user experience design and natural language processing helps you create engaging and effective conversation flows for chatbots and virtual assistants. Transform your customer support, sales, and HR processes with AI-powered solutions that enhance communication and streamline operations. ", expanded: false },
        { title:"Evaluation and Analytics", content: "Unlock the full value of your AI investments with our Evaluation and Analytics services. We implement the right evaluation metrics and analytics tools, empowering your organization to track, optimize, and elevate the performance of GPT models. Make data-driven decisions and drive continuous improvement, ensuring long-term success in your AI-powered endeavors.", expanded: false },
    ],

    GPT_content:{
        title:"Chat - GPT",
        home:"home",
        links:"Main Service",
        whoWeAre:"Who We Are",
        text_1:"With its vast knowledge base and advanced language processing capabilities,ChatGPT is ready to unlock the power of language with expert natural language processing consulting and customized training services. Natural language processing has become a crucial component of successful businesses, helping organizations understand and analyze text-based data through machine learning and other techniques. However, navigating the complex world of natural language processing can be challenging. That's where our consulting and training services come in.",
        text_2:"Navigating the complex world of communication and information exchange can be challenging, but with our customized training and consulting services, ChatGPT can help you overcome these obstacles. Our team of experts will work with you to understand your communication needs and develop a strategy that is tailored to your requirements. Whether you need to improve your writing skills or you want to automate your customer support process, our ChatGPT consulting and training services will help you get there.",
        text_3:"Empower Your AI Journey: Tailored Solutions, Expert Guidance, Superior Results",
        text_4:"Don't settle for subpar communication and information exchange. Partner with us and unlock the power of ChatGPT with expert consulting and customized training services.",
        text_5:"With our extensive experience and expertise, we can help you navigate the complexities of communication and information exchange and ensure that you're making the most of ChatGPT's capabilities. We're dedicated to providing our clients with the highest level of service, and we'll work with you every step of the way to ensure that your communication and information exchange needs are met.",
        text_6:"Don't settle for subpar communication and information exchange. Partner with us and unlock the power of ChatGPT with expert consulting and customized training services.",
    },

    //<!-- Posted-Projects-start -->
    PostedProjects:{
        Homepage:{
            title:"Apply for current posted projects",
            description:"Our current list of project looking for talents. Please only apply to projects that fits your skills and experiences.",
            side_title_1:"Publishing a Task",
            side_description_1:"The recruitment specialist will contact you within 1 hour and accurately identify the talent within 1 hour.",
            side_button_1:"Publish Task",
            side_title_2:"Update your profile",
            side_description_2:"Complete engineer talent profiles will ensure you getting the best task matching results and more exposure to employers.",
            side_button_2:"Update Profile",

            button_1:"Previous",
            button_2:"Next",
        },
        Task_detail:{
            title_1:"Talent Type",
            title_2:"Prefered completion timeline",
            title_3:"Total Budget",
            title_4:"Project Introduction",
            button_1:"Apply",
            button_2:"Applied",
            description_1:"talent have applied"
        },
        PromptBox:{
            title:"Submit My Application",
            content:"We will send your profile to this employer. Please ensure your skills matches the task description and daily pay is within your expectation. Once employer decided, employer will contact you directly or contact you through us. Please keep an eye on the inbox in your My Center page.",
            button_1:"Close",
            button_2:"Confirm",
        },
    },

    PostedProjectsDetail:{
        Homepage:{
            title_1:"Estimated daily wage：",
            title_2:"Estimated total price：",
            title_3:"Estimated time：",
            title_4:"Download attachment",
            title_5:"Requirement description",

            side_title_1:"Publishing a Task",
            side_description_1:"The recruitment specialist will contact you within 1 hour and accurately identify the talent within 1 hour.",
            side_button_1:"Publish Task",
            side_title_2:"Update your profile",
            side_description_2:"Complete engineer talent profiles will ensure you getting the best task matching results and more exposure to employers.",
            side_button_2:"Update Profile",

            button_1:"Apply",
        },
        PromptBox:{
            title:"Confirm delivery",
            content:"Make sure your skills match your needs and that your daily pay is within your acceptable range. After a successful match, the platform and employer will contact you.We will send your profile to this employer. Please ensure your skills matches the task description and daily pay is within your expectation. Once employer decided, employer will contact you directly or contact you through us. Please keep an eye on the inbox in your My Center page.",
            button_1:"Close",
            button_2:"Confirm",
        },
    },

    //<!-- Past-Projects-start -->
    PastProjects:{
        Homepage:{
            title:"View successful cases",
            description:"We have more than 10,000 successful cases on our platform. Here you can find examples that fit or are similar to your project.",
            side_title_1:"Publishing a Task",
            side_description_1:"The recruitment specialist will contact you within 1 hour and accurately identify the talent within 1 hour.",
            side_button_1:"Publish Task",
            side_title_2:"Update your profile",
            side_description_2:"Complete engineer talent profiles will ensure you getting the best task matching results and more exposure to employers.",
            side_button_2:"Update Profile",

            button_1:"Previous",
            button_2:"Next",
        },
        Detail:{
            title_1:"Type of Project",
            title_2:"Project Introduction"
        },
    },

    //<!-- Reviews-start -->
    Reviews:{
        title:"Customer Reviews",
        description:"",
        title_1:"Number of orders: ",
        title_2:"Work Experience: ",
        side_title_1:"Publishing a Task",
        side_description_1:"The recruitment specialist will contact you within 1 hour and accurately identify the talent within 1 hour.",
        side_button_1:"Publish Task",
        side_title_2:"Update your profile",
        side_description_2:"Complete engineer talent profiles will ensure you getting the best task matching results and more exposure to employers.",
        side_button_2:"Update Profile",

        button_1:"Previous",
        button_2:"Next",
    },

    //<!-- Login-Register-start -->
    Login:{
        title_1:"Please sign in",
        title_2:"Agree to terms and conditions",
        title_3:"Don't have a Taccelerator account?",
        email:"Email",
        password:"Password",
        button_1:"SIGN IN",
        button_2:"SIGN UP",
        Tips:{
            tip_email:"Email address is required",
            tip_password_1:"Password is required",
            tip_password_2:"The password or account number is incorrect",
            tip_terms:"You must agree before submitting.",
        }
    },

    Register_identify:{
        title:"Join as client or freelancer",
        client:"CLIENT",
        freelancer:"FREELANCER",
        client_description_1:"I'm a client.",
        client_description_2:"Looking for help",
        client_description_3:"to complete my project.",
        freelancer_description_1:"I'm a freelancer.",
        freelancer_description_2:"I'm looking for a job",
        freelancer_description_3:"that meets my expectations.",
        button:"SIGN UP",
    },

    Register:{
        title_1:"Sign up to get what you need",
        title_2:"Have an account already?",
        title_3:"Agree to terms and conditions",
        firstname:"First name",
        lastname:"Last name",
        email:"Email",
        code:"Verification code",
        password:"Password",
        select:"Selection Area",
        select_1:"China",
        select_2:"Canada",
        button:"CREATE MY ACCOUNT",
        link:"Login in",
        Tips:{
            tip_fristname:"First name is required",
            tip_lastname:"Last name is required",
            tip_name:"Sorry! No special characters or numbers",
            tip_email:"The email address has been registered",
            tip_email_1:"Email address is required",
            tip_email_2:"The email is not formatted correctly!",
            tip_password_1:"Password is required",
            tip_password_2:"The password security strength level is too low",
            tip_area:"Please select your area",
            tip_terms:"You must agree before submitting",
            tip_code:"Please enter the verification code",
        }
    },

    Register_freelancer:{
        step:{
            title_1:"Let's take 5-10 minutes to introduce ourselves!",
            title_2:"We need to know your experience and skills, and some necessary personal information.",
            title_3:"Your introduction, better yet, is how you stand out from the crowd. This is what you use to win jobs, so let's get it right together.",
            title_4:"-- Advice from Taccelerator",
            button_1:"FILL IN",
            button_2:"BACK",
            button_3:"SUBMIT",
        },
        step1:{
            title_1:"Now add the name of your profession to let everyone know what you're doing.",
            title_2:"We need to know your experience and skills, and some necessary personal information.",
            tip:"At least 4 characters are required",
        },
        step2:{
            title_1:"Now tell us about your skills and abilities.",
            title_2:"Do your skills show clients what you have to offer and let us know what jobs to recommend to you.",
            title_3:"You can describe your skills and relevant strengths, which will make you more attractive to employers.",
            title_4:"At least 100 characters",
            tip:"Technical and skill introductions are needed",
        },
        step3:{
            title_1:"Now tell us about your project experience.",
            title_2:"Experienced workers are more likely to gain the client's trust, so we can write it as well as possible.",
            title_3:"You can describe the requirements, implementation outcomes, and related technologies of the project you are involved in.",
            title_4:"At least 100 characters",
            tip:"Project experience are needed",
        },
        step4:{
            title_1:"Clients will always look for cases similar to their own projects.",
            title_2:"You can have no cases, but adding relevant cases will make your profile more visible.",
            title_3:"You can describe the needs of the project, the talentrequirements, the reference products, and how to collaborate.",
        },
        step5:{
            title_1:"Fill in your previous or current employer and your entire working life.",
            title_2:"This is not necessary, but a customer may be interested in employees of a particular business or in the length of time an employee has worked.",
            title_3:"Previous Company",
            title_4:"Years of Experiences",
            tip_1:"Fill in your Unit",
            tip_2:"Fill in your Working life"
        },
        step6:{
            title_1:"The last few details, we're almost done.",
            title_2:"Don't worry. We keep this information confidential. Only you can see it.",
            title_3:"Street address",
            title_4:"City",
            title_5:"State/Province",
            title_6:"Zip",
            title_7:"Phone",
            tip_1:"Fill in your street address",
            tip_2:"Please provide a valid city",
            tip_3:"Please select a valid state",
            tip_4:"Please provide a valid zip",
            tip_5:"Fill in your phone number",
        },
    },

    //<!-- Personal-Page-start -->
    PersonalPage_client:{
      message:{
        title:"Message",
        actionbar:"View message",
        detail_title_1:"Messages",
        detail_title_2:"Freelancer",
        detail_title_3:"Customer service",
      }, 
      requirement:{
        title:"Requirements",
        actionbar:"All Requirements",
        detail_title_1:"Requirements",
        detail_title_2:"In Progress",
        detail_title_3:"Closed",
        button_1:"Previous",
        button_2:"Next",
      }, 
      requirement_detail:{
        title_1:"Estimated daily wage：",
        title_2:"Estimated total price：",
        title_2:"Estimated time：",
        title_2:"Requirement description",
        button:"Cancel Request",

        side_title_1:"Publishing a Task",
        side_description_1:"The recruitment specialist will contact you within 1 hour and accurately identify the talent within 1 hour.",
        side_button_1:"Publish Task",
        side_title_2:"Applicants for your tasks",
        side_description_2:"Review applicants for your published tasks, you may choose to contact the applicant directly or contact us. Our recuriting specialist will build a team most suitable for your needs.",
        side_button_2:"View Details",
      },
      order:{
        title:"Order",
        actionbar:"Full Order",
        detail_title_1:"Orders",
        detail_title_2:"Unpaid",
        detail_title_3:"Cancelled",
        detail_title_4:"To be accepted",
        detail_title_5:"To be evaluated",
        detail_title_6:"Pending refund",

        button_1:"Previous",
        button_2:"Next",
      }, 
      order_detail:{
        title_1:"Engineer information",
        title_2:"Company: ",
        title_3:"Position: ",
        title_4:"Experience: ",
        title_5:"Daily wage: ",
        title_6:"Order content",
        title_7:"Type of cooperation: ",
        title_8:"Project start time: ",
        title_9:"Project end time: ",
        title_10:"Item amount: ",
        title_11:"Order status: ",
        title_12:"Release time: ",
        button:"Cancel",

        side_title_1:"Publishing a Task",
        side_description_1:"The recruitment specialist will contact you within 1 hour and accurately identify the talent within 1 hour.",
        side_button_1:"Publish Task",
      },
      Setting:{
        title:"Setting",
        actionbar:"Change Password",
        detail_title_1:"Setting",
        detail_title_2:"Change Password",
        detail_title_3:"Old Password",
        detail_title_4:"New Password",
        detail_title_5:"Confirm New Password",
        button_1:"Save",

        tip_1:"Please enter the old password",
        tip_2:"Please enter a new password",
        tip_3:"Please enter the new password again !",
      }
    },
    PersonalPage_freelancer:{
        message:{
            title:"Message",
            actionbar:"View message",
            detail_title_1:"Messages",
            detail_title_2:"Client",
            detail_title_3:"Customer service",
        }, 
        order:{
            title:"Order",
            actionbar:"Full Order",
            detail_title_1:"Orders",
            detail_title_2:"Unpaid",
            detail_title_3:"Cancelled",
            detail_title_4:"To be accepted",
            detail_title_5:"To be evaluated",
            detail_title_6:"Pending refund",
    
            button_1:"Previous",
            button_2:"Next",
        }, 
        order_detail:{
            title_1:"Engineer information",
            title_2:"Company: ",
            title_3:"Position: ",
            title_4:"Experience: ",
            title_5:"Daily wage: ",
            title_6:"Order content",
            title_7:"Type of cooperation: ",
            title_8:"Project start time: ",
            title_9:"Project end time: ",
            title_10:"Item amount: ",
            title_11:"Order status: ",
            title_12:"Release time: ",
            button:"Cancel",
    
            side_title_1:"Update your profile",
            side_description_1:"Complete engineer talent profiles will ensure you getting the best task matching results and more exposure to employers.",
            side_button_1:"Update profile",
        },
        updateprofile:{
            step1:{
                title_1:"The name of your profession",
                tip:"At least 4 characters are required",
            },
            step2:{
                title_1:"The skills you have",
                title_2:"At least 100 characters",
                tip:"Technical and skill introductions are needed",
            },
            step3:{
                title_1:"The experience you have",
                title_2:"At least 100 characters",
                tip:"Project experience are needed",
            },
            step4:{
                title_1:"The successful case you have.",
            },
            step5:{
                title_1:"Your most recent work experience",
                title_2:"Previous Company",
                title_3:"Years of Experiences",
                tip_1:"Fill in your Unit",
                tip_2:"Fill in your Working life"
            },
            step6:{
                title_1:"Your other information",
                title_2:"Street address",
                title_3:"City",
                title_4:"State/Province",
                title_5:"Zip",
                title_6:"Zip",
                tip_1:"Fill in your street address",
                tip_2:"Please provide a valid city",
                tip_3:"Please select a valid state",
                tip_4:"Please provide a valid zip",
                tip_5:"Fill in your phone number",
            },
        },
        Setting:{
            title:"Setting",
            actionbar:"Change Password",
            detail_title_1:"Setting",
            detail_title_2:"Change Password",
            detail_title_3:"Old Password",
            detail_title_4:"New Password",
            detail_title_5:"Confirm New Password",
            button_1:"Save",
    
            tip_1:"Please enter the old password",
            tip_2:"Please enter a new password",
            tip_3:"Please enter the new password again !",
          }
    },
}