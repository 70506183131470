import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { createI18n } from "vue-i18n";
import zh from './lang/cn';
import en from './lang/en';

const i18n = createI18n({
    locale: 'en',
    messages: {
        zh,
        en,
    },
});

createApp(App)
    .use(i18n)
    .use(store)
    .use(router)
    .mount("#app");
