<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <a class="navbar-brand" href="#" @click.prevent="cilikactive(0)"
      >Taccelerator</a
    >
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto">
        <li v-if="state == 1" class="nav-item">
          <a
            class="nav-link active"
            href="#"
            @click.prevent="cilikactive(1)"
            id="1"
            >{{ $t("navigation.home")
            }}<span class="sr-only">(current)</span></a
          >
        </li>
        <li v-if="state != 1" class="nav-item">
          <a class="nav-link" href="#" @click.prevent="cilikactive(1)" id="1"
            >{{ $t("navigation.home")
            }}<span class="sr-only">(current)</span></a
          >
        </li>
        <li v-if="(state == 2&&ip.country!='China')|(state == 2&&cid!=null)" class="nav-item">
          <a
            class="nav-link active"
            href="#"
            id="2"
            @click.prevent="cilikactive(2)"
            >{{ $t("navigation.findTalents") }}</a
          >
        </li>
        <li v-if="(state != 2&&ip.country!='China')|(state != 2&&cid!=null)" class="nav-item">
          <a class="nav-link" href="#" id="2" @click.prevent="cilikactive(2)">{{
            $t("navigation.findTalents")
          }}</a>
        </li>
        <li v-if="state == 3" class="nav-item">
          <a
            class="nav-link active"
            href="#/case"
            id="3"
            @click.prevent="cilikactive(3)"
            >{{ $t("navigation.pastProjects") }}</a
          >
        </li>
        <li v-if="state != 3" class="nav-item">
          <a
            class="nav-link"
            href="#/case"
            id="3"
            @click.prevent="cilikactive(3)"
            >{{ $t("navigation.pastProjects") }}</a
          >
        </li>
        <li v-if="state == 10" class="nav-item">
          <a
            class="nav-link active"
            href="#/case"
            id="3"
            @click.prevent="cilikactive(10)"
            >{{ $t("navigation.postedProjects") }}</a
          >
        </li>
        <li v-if="state != 10" class="nav-item">
          <a
            class="nav-link"
            href="#/case"
            id="3"
            @click.prevent="cilikactive(10)"
            >{{ $t("navigation.postedProjects") }}</a
          >
        </li>
        <li v-if="state == 4" class="nav-item">
          <a
            class="nav-link active"
            href="#/reputation"
            id="4"
            @click.prevent="cilikactive(4)"
            >{{ $t("navigation.reviews") }}</a
          >
        </li>
        <li v-if="state != 4" class="nav-item">
          <a
            class="nav-link"
            href="#/reputation"
            id="4"
            @click.prevent="cilikactive(4)"
            >{{ $t("navigation.reviews") }}</a
          >
        </li>
        <li v-if="state == 5" class="nav-item">
          <a
            class="nav-link active"
            href="#/blogs"
            id="4"
            @click.prevent="cilikactive(5)"
            >{{ $t("navigation.blogs") }}</a
          >
        </li>
        <li v-if="state != 5" class="nav-item">
          <a
            class="nav-link"
            href="#/blogs"
            id="4"
            @click.prevent="cilikactive(5)"
            >{{ $t("navigation.blogs") }}</a
          >
        </li>
        <li v-if="state == 6" class="nav-item">
          <a
            class="nav-link active"
            href="#/investor"
            id="4"
            @click.prevent="cilikactive(6)"
            >{{ $t("navigation.contactUs") }}</a
          >
        </li>
        <li v-if="state != 6" class="nav-item">
          <a
            class="nav-link"
            href="#/investor"
            id="4"
            @click.prevent="cilikactive(6)"
            >{{ $t("navigation.contactUs") }}</a
          >
        </li>

        <li v-if="state == 7" class="nav-item dropdown">
          <a
            class="nav-link active dropdown-toggle"
            href="#"
            id="navbarDropdown"
            role="button"
            data-toggle="dropdown"
            aria-expanded="false"
          >
            {{ $t("navigation.mainService") }}
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdown">
            <a
              class="dropdown-item"
              href="#/reputation"
              @click.prevent="GoColumn(1)"
              >{{ $t("navigation.financialTechnologyReporting") }}</a
            >
            <a class="dropdown-item" href="#" @click.prevent="GoColumn(2)">{{
              $t("navigation.cloudServices")
            }}</a>
            <a class="dropdown-item" href="#" @click.prevent="GoColumn(3)">{{
              $t("navigation.webCraw")
            }}</a>
            <a class="dropdown-item" href="#" @click.prevent="GoColumn(4)">{{
              $t("navigation.businessInteligence")
            }}</a>
            <a class="dropdown-item" href="#" @click.prevent="GoColumn(5)">{{
              $t("navigation.chatGPT")
            }}</a>
          </div>
        </li>
        <li v-if="state != 7" class="nav-item dropdown">
          <a
            class="nav-link dropdown-toggle"
            href="#"
            id="navbarDropdown"
            role="button"
            data-toggle="dropdown"
            aria-expanded="false"
          >
            {{ $t("navigation.mainService") }}
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdown">
            <a
              class="dropdown-item"
              href="#/reputation"
              @click.prevent="GoColumn(1)"
              >{{ $t("navigation.financialTechnologyReporting") }}</a
            >
            <a class="dropdown-item" href="#" @click.prevent="GoColumn(2)">{{
              $t("navigation.cloudServices")
            }}</a>
            <a class="dropdown-item" href="#" @click.prevent="GoColumn(3)">{{
              $t("navigation.webCraw")
            }}</a>
            <a class="dropdown-item" href="#" @click.prevent="GoColumn(4)">{{
              $t("navigation.businessInteligence")
            }}</a>
            <a class="dropdown-item" href="#" @click.prevent="GoColumn(5)">{{
              $t("navigation.chatGPT")
            }}</a>
          </div>
        </li>
      </ul>
      <form class="form-inline my-2 my-lg-0">
        <input
          class="form-control mr-sm-2"
          type="search"
          :placeholder="$t('navigation.search')"
          aria-label="Search"
          v-model="keyWord"
        />
        <button
          class="btn btn-outline-success my-2 my-sm-0"
          type="submit"
          @click="onsubmit()"
        >
          {{ $t("navigation.search") }}
        </button>
      </form>
      <ul class="navbar-nav" v-if="client == null">
        <li class="nav-item" v-if="state != 8">
          <a class="nav-link" href="#/login" @click.prevent="cilikactive(8)"
            >{{ $t("navigation.login") }}
            <span class="sr-only">(current)</span></a
          >
        </li>
        <li class="nav-item" v-if="state == 8">
          <a
            class="nav-link active"
            href="#/login"
            @click.prevent="cilikactive(8)"
            >{{ $t("navigation.login") }}
            <span class="sr-only">(current)</span></a
          >
        </li>
      </ul>
      <ul class="navbar-nav" v-if="client != null">
        <li v-if="state != 9" class="nav-item dropdown">
          <a
            class="nav-link dropdown-toggle"
            href="#"
            id="navbarDropdown"
            role="button"
            data-toggle="dropdown"
            aria-expanded="false"
          >
            {{ $t("navigation.myCenter") }}
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdown">
            <a
              class="dropdown-item"
              href="#/personalpage"
              @click.prevent="cilikactive(9)"
              >{{ $t("navigation.homePage") }}</a
            >
            <a class="dropdown-item" href="#" @click.prevent="LoginOut()">{{
              $t("navigation.loginOut")
            }}</a>
          </div>
        </li>
        <li v-if="state == 9" class="nav-item dropdown">
          <a
            class="nav-link active dropdown-toggle"
            href="#"
            id="navbarDropdown"
            role="button"
            data-toggle="dropdown"
            aria-expanded="false"
          >
            {{ $t("navigation.myCenter") }}
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdown">
            <a
              class="dropdown-item"
              href="#/personalpage"
              @click.prevent="cilikactive(9)"
              >{{ $t("navigation.homePage") }}</a
            >
            <a class="dropdown-item" href="#" @click.prevent="LoginOut()">{{
              $t("navigation.loginOut")
            }}</a>
          </div>
        </li>
      </ul>
    </div>
  </nav>
</template>
<script>
import axios from "axios";
export default {
  name: "Header",
  setup() {
    let cid;
    let client = JSON.parse(sessionStorage.getItem("client"));
    if(client!=null){
      cid=client.cid;
    }else{
      cid=null;
    }
    let ip = JSON.parse(sessionStorage.getItem("IP"));
    return {
      client,
      ip,
      cid,
    };
  },
  data() {
    return {
      state: 1,
      keyWord: "",
      talent: null,
    };
  },
  methods: {
    LoginOut() {
      sessionStorage.removeItem("client");
      this.$router.push({ path: "/" });
      if (sessionStorage.getItem("state") == 1) {
        location.reload();
      }
      sessionStorage.setItem("state", 1);
    },
    cilikactive: function (node) {
      if (node == 0) {
        sessionStorage.setItem("state", 1);
        this.$router.push({ path: "/" });
      } else if (node == 1) {
        sessionStorage.setItem("state", 1);
        this.$router.push({ path: "/" });
      } else if (node == 2) {
        sessionStorage.setItem("state", 2);
        this.$router.push({ path: "/talents" });
      } else if (node == 3) {
        sessionStorage.setItem("state", 3);
        this.$router.push({ path: "/case" });
      } else if (node == 4) {
        sessionStorage.setItem("state", 4);
        this.$router.push({ path: "/reputation" });
      } else if (node == 5) {
        sessionStorage.setItem("state", 5);
        this.$router.push({ path: "/blogs" });
      } else if (node == 6) {
        sessionStorage.setItem("state", 6);
        this.$router.push({ path: "/investor" });
      } else if (node == 8) {
        sessionStorage.setItem("state", 8);
        this.$router.push({ path: "/login" });
      } else if (node == 9) {
        sessionStorage.setItem("state", 9);
        if (this.client.cid != null) {
          this.$router.push({ path: "/personalpage" });
        } else {
          if (this.talent == null) {
            sessionStorage.setItem("email", this.client.email);
            sessionStorage.setItem("condition", 1);
            this.$router.push({ path: "/freelancer" });
          } else {
            this.$router.push({ path: "/freelancer/homepage" });
          }
        }
      } else if (node == 10) {
        sessionStorage.setItem("state", 10);
        this.$router.push({ path: "/tasks" });
      }
    },
    onsubmit() {
      if (this.keyWord != "") {
        this.$router.push({
          path: "/talentretrieval",
          query: { keyWord: this.keyWord },
        });
      }
    },
    GoColumn(id) {
      sessionStorage.setItem("state", 7);
      this.$router.push({ path: "/column", query: { id: id } });
    },
  },
  created() {
    const that = this;
    that.state = sessionStorage.getItem("state");
    let client = JSON.parse(sessionStorage.getItem("client"));
    if (client != null) {
      if (client.fid != null) {
        let id = client.tid;
        axios
          .post("/api/gettalentbyid?id=" + id)
          .then(function (resp) {
            that.talent = resp.data;
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
    axios.get('https://httpbin.org/ip')
      .then(response => {
        const ip = response.data.origin;
        // 使用 ip 地址
        axios.get('http://realip.cc/?ip='+ip).then(
          response => {
            let dataStr = JSON.stringify(response.data);
            sessionStorage.setItem("IP", dataStr);
          }
        )
    })
  },
};
</script>
