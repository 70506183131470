<template>
  <footer class="container-fluid foot-wrap">
    <!--采用container，使得页尾内容居中 -->
    <div class="container">
      <div class="row">
        <div class="row-content col-lg-4 col-sm-8 col-xs-12">
          <h3>{{ $t("footer.user") }}</h3>
          <ul>
            <li><a href="#/login" @click.prevent="jump(8)">{{ $t("footer.login") }}</a></li>
            <li><a href="#/register" @click.prevent="jump(9)">{{ $t("footer.register") }}</a></li>
          </ul>
        </div>
        <div class="row-content col-lg-4 col-sm-8 col-xs-12">
          <h3>{{ $t("footer.browse") }}</h3>
          <ul>
            <li><a href="#/" @click.prevent="jump(1)">{{ $t("footer.home") }}</a></li>
            <li v-if="ip.country!='China'"><a href="#/talents" @click.prevent="jump(2)">{{ $t("footer.findTalents") }}</a></li>
            <li><a href="#/case" @click.prevent="jump(3)">{{ $t("footer.pastProjects") }}</a></li>
            <li><a href="#/task" @click.prevent="jump(10)">{{ $t("footer.postedProjects") }}</a></li>
            <li><a href="#/reputation" @click.prevent="jump(4)">{{ $t("footer.reviews") }}</a></li>
            <li><a href="#/blogs" @click.prevent="jump(5)">{{ $t("footer.blogs") }}</a></li>
            <li><a href="#/investor" @click.prevent="jump(6)">{{ $t("footer.contactUs") }}</a></li>
          </ul>
        </div>
        <div class="row-content col-lg-4 col-sm-8 col-xs-12">
          <h3>{{ $t("footer.mainService") }}</h3>
          <ul>
            <li><a href="#" @click.prevent="GoColumn(1)">{{ $t("footer.financialTechnologyReporting") }}</a></li>
            <li><a href="#" @click.prevent="GoColumn(2)">{{ $t("footer.cloudServices") }}</a></li>
            <li><a href="#" @click.prevent="GoColumn(3)">{{ $t("footer.webCraw") }}</a></li>
            <li><a href="#" @click.prevent="GoColumn(4)">{{ $t("footer.businessInteligence") }}</a></li>
            <li><a href="#" @click.prevent="GoColumn(5)">{{ $t("footer.chatGPT") }}</a></li>
          </ul>
        </div>
      </div>
      <!--/.row -->
    </div>
    <!--/.container-->

    <!-- 社交图标列表，其中图标采用的是Font Awesome图标字体库-->
    <ul class="social">
      <li>
        <a href="#" title="Twitter Profile">
          <span class="icon fa fa-twitter"></span>
        </a>
      </li>
      <li>
        <a href="#" title="Facebook Page">
          <span class="icon fa fa-facebook"></span>
        </a>
      </li>
      <li>
        <a href="#" title="LinkedIn Profile">
          <span class="icon fa fa-linkedin"></span>
        </a>
      </li>
      <li>
        <a href="#" title="Goole+ Profile">
          <span class="icon fa fa-google"></span>
        </a>
      </li>
      <li>
        <a href="#" title="Github Profile">
          <span class="icon fa fa-github"></span>
        </a>
      </li>
    </ul>
    <p align="center" style="margin-top: 20px; color: #878b91">
      Copyright &copy;2023 Taccelerator
    </p>
  </footer>
</template>
<script>
export default {
  name: "Footer",
  setup() {
    let client = JSON.parse(sessionStorage.getItem("client"));
    let ip = JSON.parse(sessionStorage.getItem("IP"));
    return {
      client,
      ip,
    };
  },
  methods:{
    GoColumn(id){
      sessionStorage.setItem("state", 7);
      this.$router.push({ path: "/column", query: { id: id } });
    },
    jump(node){
      if(node==1){
            sessionStorage.setItem("state", 1);
            this.$router.push({ path: "/" });
        }else if(node==2){
            sessionStorage.setItem("state", 2);
            this.$router.push({ path: "/talents" });
        }else if(node==3){
            sessionStorage.setItem("state", 3);
            this.$router.push({ path: "/case" });
        }else if(node==4){
            sessionStorage.setItem("state", 4);
            this.$router.push({ path: "/reputation" });
        }else if(node==5){
            sessionStorage.setItem("state", 5);
            this.$router.push({ path: "/blogs" });
        }else if(node==6){
            sessionStorage.setItem("state", 6);
            this.$router.push({ path: "/investor" });
        }else if(node==8){
            sessionStorage.setItem("state", 8);
            this.$router.push({ path: "/login" });
        }else if(node==9){
            sessionStorage.setItem("state", 8);
            this.$router.push({ path: "/register" });
        } else if (node == 10) {
        sessionStorage.setItem("state", 10);
        this.$router.push({ path: "/tasks" });
      }
    },
  }
};
</script>
<style>
.foot-wrap {
  background: rgba(0, 0, 0, 0) url("~@/assets/img/home1/footer-bg.png") no-repeat scroll center center / cover;
  margin-top: 80px;
}
.row-content h3 {
  color: #ddd;
  font-size: 16px;
  font-weight: 300;
  line-height: 16px;
  margin: 40px 0 30px 34px;
}

.row-content ul {
  font-size: 13px;
  line-height: 24px;
}

.row-content ul li {
  list-style: none;
}

.row-content ul li a {
  color: #878b91;
}

.row-content ul li a:hover {
  text-decoration: none;
  color: #c1ba62;
}
ul.social {
  margin: 0;
  padding: 0;
  width: 100%;
  text-align: center;
}
ul.social li {
  display: inline-block;
}
ul.social li a {
  display: inline-block;
  font-size: 18px;
  line-height: 30px;
  border-radius: 36px;
  background-color: gray-light;
  color: #fff;
  margin: 0 3px 3px 0;
}
ul.social li a:hover {
  text-decoration: none;
  background-color: link-hover-color;
}
</style>
