<template>
  <Header></Header>
  <Home></Home>
  <Footer></Footer>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header.vue";
import Home from "@/components/Home.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "HomeView",
  components: {
    Header,
    Home,
    Footer,
  },
};
</script>
