import { createRouter, createWebHashHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/talents",
    name: "talents",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/TalentView/TalentsView.vue"
      ),
  },
  {
    path: "/case",
    name: "case",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/CaseView.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/LoginView.vue"),
  },
  {
    path: "/task",
    name: "task",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/TaskView.vue"),
  },
  {
    path: "/reputation",
    name: "reputation",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ReputationView.vue"),
  },
  {
    path: "/register",
    name: "register",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/RegisterView/RegisterView.vue"),
  },
  {
    path: "/identity",
    name: "identity",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/RegisterView/IdentityView.vue"),
  },
  {
    path: "/freelancer",
    name: "freelancer",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/RegisterView/WorkerView.vue"),
  },
  {
    path: "/freelancer/homepage",
    name: "freelancerhomepage",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/FreelancerView/PersonView.vue"),
  },
  {
    path: "/freelancer/order",
    name: "freelancerorder",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/FreelancerView/OrderView.vue"),
  },
  {
    path: "/freelancer/orderdetail",
    name: "freelancerorderdetail",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/FreelancerView/OrderDetailView.vue"),
  },
  {
    path: "/freelancer/settingpassword",
    name: "freelancersettingpassword",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/FreelancerView/SettingPasswordView.vue"),
  },
  {
    path: "/freelancer/settinginformation",
    name: "freelancersettinginformation",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/FreelancerView/SettingPerView.vue"),
  },
  {
    path: "/personalpage",
    name: "personalpage",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/PersonalView/PersonView.vue"
      ),
  },
  {
    path: "/requirements",
    name: "Requirements",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/PersonalView/RequirementsView.vue"
      ),
  },
  {
    path: "/requirementdetail",
    name: "Requirementdetail",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/PersonalView/RequireDetailView.vue"
      ),
  },
  {
    path: "/order",
    name: "order",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/PersonalView/OrderView.vue"
      ),
  },
  {
    path: "/orderdetail",
    name: "orderdetail",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/PersonalView/OrderDetailView.vue"
      ),
  },
  {
    path: "/settingcom",
    name: "settingcom",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/PersonalView/SettingComView.vue"
      ),
  },
  {
    path: "/settingper",
    name: "settingper",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/PersonalView/SettingPerView.vue"
      ),
  },
  {
    path: "/settingpassword",
    name: "settingpassword",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/PersonalView/SettingPasswordView.vue"
      ),
  },
  {
    path: "/talentdetail",
    name: "talentdetail",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/TalentView/TalentDetailView.vue"
      ),
  },
  {
    path: "/talentappt",
    name: "talentappt",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/TalentView/TalentApptView.vue"
      ),
  },
  {
    path: "/talentretrieval",
    name: "talentretrieval",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/TalentView/TalentRetrievalView.vue"
      ),
  },
  {
    path: "/adminhome",
    name: "adminhome",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/AdminView/HomeView.vue"
      ),
  },
  {
    path: "/admindemand",
    name: "admindemand",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/AdminView/DemandView.vue"
      ),
  },
  {
    path: "/adminorder",
    name: "adminorder",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/AdminView/OrderView.vue"
      ),
  },
  {
    path: "/fakeruser",
    name: "fakeruser",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/AdminView/FakeUserView.vue"
      ),
  },
  {
    path: "/adminarticle",
    name: "adminarticle",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/AdminView/ArticleView.vue"
      ),
  },
  {
    path: "/knowledgeplanet",
    name: "knowledgeplanet",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/AdminView/Knowledge_planet.vue"
      ),
  },
  {
    path: "/column",
    name: "column",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/ColumnView/ColumnView.vue"
      ),
  },
  {
    path: "/investor",
    name: "investor",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/InvestorView/InvestorView.vue"
      ),
  },
  {
    path: "/blogs",
    name: "blogs",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/BlogView/BlogsView.vue"
      ),
  },
  {
    path: "/blogdetail",
    name: "blogdetail",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/BlogView/BlogDetailView.vue"
      ),
  },
  {
    path: "/blogtags",
    name: "blogtags",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/BlogView/BlogTagsView.vue"
      ),
  },
  {
    path: "/blogretrieval",
    name: "blogretrieval",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/BlogView/BlogRetrievalView.vue"
      ),
  },
  {
    path: "/tasks",
    name: "tasks",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/TaskView/TaskView.vue"
      ),
  },
  {
    path: "/taskdetail",
    name: "taskdetail",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/TaskView/TaskDetailView.vue"
      ),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
