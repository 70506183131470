<template>
  <a id="TOPUp" href="#topAnchor" @click.prevent="GoTop()">
    <img style="width: 100%; height: 100%" src="~@/assets/top.png" alt="" />
  </a>
  <div id="myCarousel" class="carousel slide" data-ride="carousel">
    <div class="carousel-inner">
      <div class="carousel-item active">
        <img
          class="first-slide"
          src="~@/assets/img/new/home-13.jpg"
          alt="First slide"
        />
        <div class="container">
          <div class="carousel-caption text-left">
            <h1 style="color: white">{{ $t("wheelSeeding.firstTitle") }}</h1>
            <p style="color: white">
              {{ $t("wheelSeeding.firstContent") }}
            </p>
            <p>
              <a
                v-if="client == null"
                class="more martence-button active"
                href="#/login"
                role="button"
                @click.prevent="Change(1)"
                >{{ $t("wheelSeeding.firstButton") }}</a
              >
              <a
                v-if="client != null"
                class="more martence-button active"
                href="#/personalpage"
                role="button"
                @click.prevent="Change(2)"
                >Back to My Center</a
              >
            </p>
          </div>
        </div>
      </div>
      <div class="carousel-item">
        <img
          class="second-slide"
          src="~@/assets/img/new/home-15.jpg"
          alt="Second slide"
        />
        <div class="container">
          <div class="carousel-caption">
            <h1 style="color: white">{{ $t("wheelSeeding.secondTitle") }}</h1>
            <p style="color: white">
              {{ $t("wheelSeeding.secondContent") }}
            </p>
            <p>
              <a
                class="more martence-button active"
                href="#talents"
                role="button"
                @click.prevent="Change(3)"
                >{{ $t("wheelSeeding.secondButton") }}</a
              >
            </p>
          </div>
        </div>
      </div>
      <div class="carousel-item">
        <img
          class="third-slide"
          src="~@/assets/img/new/home-28.jpg"
          alt="Third slide"
        />
        <div class="container">
          <div class="carousel-caption text-right">
            <h1 style="color: white">{{ $t("wheelSeeding.thirdTitle") }}</h1>
            <p style="color: white">
              {{ $t("wheelSeeding.thirdContent") }}
            </p>
            <p>
              <a
                class="more martence-button active"
                href="#"
                role="button"
                @click.prevent="Change(4)"
                >{{ $t("wheelSeeding.thirdButton") }}</a
              >
            </p>
          </div>
        </div>
      </div>
    </div>
    <a
      class="carousel-control-prev"
      href="#myCarousel"
      role="button"
      data-slide="prev"
    >
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a
      class="carousel-control-next"
      href="#myCarousel"
      role="button"
      data-slide="next"
    >
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a>
  </div>

  <!-- slider-area-end -->
  <!-- welcome-area-start -->
  <div class="welcome-area">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-md-12 col-sm-12 col-12">
          <div class="section-title">
            <h2 class="title"><span>Welcome to</span> Taccelerator</h2>
          </div>
          <div class="welcome-content">
            <div class="single-service">
              <div class="icon">
                <span class="flaticon-project"></span>
              </div>
              <div class="content">
                <h2 class="title">{{ $t("welcome.targetTitle") }}</h2>
                <p class="text">
                  {{ $t("welcome.targetContent") }}
                </p>
              </div>
            </div>
            <div class="single-service">
              <div class="icon">
                <span class="flaticon-idea"></span>
              </div>
              <div class="content">
                <h2 class="title">{{ $t("welcome.visionTitle") }}</h2>
                <p class="text">
                  {{ $t("welcome.visionContent") }}
                </p>
              </div>
            </div>
            <div class="single-service">
              <div class="icon">
                <span class="flaticon-responsive-1"></span>
              </div>
              <div class="content">
                <h2 class="title">{{ $t("welcome.valuesTitle") }}</h2>
                <p class="text">
                  {{ $t("welcome.valuesContent") }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- welcome-area-end -->
  <!-- h2-why-area-start -->
  <div class="h2-why-area bg-with-black">
    <div class="container">
      <div class="row">
        <div
          class="col-lg-6 offset-lg-3 col-md-8 offset-lg-2 col-sm-10 offset-sm-1 col-12"
        >
          <div class="h2-section-title color-white">
            <h1 class="title">{{ $t("why.whyTitle") }}</h1>
            <p class="text">
              {{ $t("why.whyContent") }}
            </p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-12">
          <div class="accordion" id="accordion">
            <div class="card">
              <div class="card-header" id="headingOne">
                <h5 class="mb-0">
                  <button
                    class="btn btn-link"
                    data-toggle="collapse"
                    data-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    {{ $t("why.firstTitle") }}
                  </button>
                </h5>
              </div>

              <div
                id="collapseOne"
                class="collapse show"
                aria-labelledby="headingOne"
                data-parent="#accordion"
              >
                <div class="card-body">
                  {{ $t("why.firstContent") }}
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-header" id="headingTwo">
                <h5 class="mb-0">
                  <button
                    class="btn btn-link collapsed"
                    data-toggle="collapse"
                    data-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    {{ $t("why.secondTitle") }}
                  </button>
                </h5>
              </div>
              <div
                id="collapseTwo"
                class="collapse"
                aria-labelledby="headingTwo"
                data-parent="#accordion"
              >
                <div class="card-body">
                  {{ $t("why.secondContent") }}
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-header" id="headingThree">
                <h5 class="mb-0">
                  <button
                    class="btn btn-link collapsed"
                    data-toggle="collapse"
                    data-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    {{ $t("why.thirdTitle") }}
                  </button>
                </h5>
              </div>
              <div
                id="collapseThree"
                class="collapse"
                aria-labelledby="headingThree"
                data-parent="#accordion"
              >
                <div class="card-body">
                  {{ $t("why.thirdContent") }}
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-header" id="headingFour">
                <h5 class="mb-0">
                  <button
                    class="btn btn-link collapsed"
                    data-toggle="collapse"
                    data-target="#collapseFour"
                    aria-expanded="false"
                    aria-controls="collapseFour"
                  >
                    {{ $t("why.fourthTitle") }}
                  </button>
                </h5>
              </div>
              <div
                id="collapseFour"
                class="collapse"
                aria-labelledby="headingFour"
                data-parent="#accordion"
              >
                <div class="card-body">
                 {{ $t("why.fourthTitle") }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-12">
          <div class="all-progess">
            <div class="skills-progress" data-sr="enter">
              <p class="progressbar-title">{{ $t("navigation.chatGPT") }}</p>
              <div class="progress">
                <div
                  class="progress-bar"
                  role="progressbar"
                  aria-valuenow="60"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  style="width: 15%"
                >
                  <span>97%</span>
                </div>
              </div>
            </div>
            <div class="skills-progress" data-sr="enter">
              <p class="progressbar-title">{{ $t("navigation.financialTechnologyReporting") }}</p>
              <div class="progress">
                <div
                  class="progress-bar"
                  role="progressbar"
                  aria-valuenow="60"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  style="width: 97%"
                >
                  <span>97%</span>
                </div>
              </div>
            </div>
            <div class="skills-progress" data-sr="enter">
              <p class="progressbar-title">{{ $t("navigation.cloudServices") }}</p>
              <div class="progress">
                <div
                  class="progress-bar"
                  role="progressbar"
                  aria-valuenow="60"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  style="width: 86%"
                >
                  <span>86%</span>
                </div>
              </div>
            </div>
            <div class="skills-progress" data-sr="enter">
              <p class="progressbar-title">{{ $t("navigation.webCraw") }}</p>
              <div class="progress">
                <div
                  class="progress-bar"
                  role="progressbar"
                  aria-valuenow="60"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  style="width: 99%"
                >
                  <span>99%</span>
                </div>
              </div>
            </div>
            <div class="skills-progress" data-sr="enter">
              <p class="progressbar-title">{{ $t("navigation.businessInteligence") }}</p>
              <div class="progress">
                <div
                  class="progress-bar"
                  role="progressbar"
                  aria-valuenow="60"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  style="width: 89%"
                >
                  <span>89%</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- h2-why-area-end -->
  <!-- service-area-start -->
  <div class="service-area">
    <div class="container">
      <div class="row">
        <div class="col-lg-4 col-md-12 col-sm-12 col-12">
          <div class="service-info">
            <h2 class="title">{{ $t("service.serviceTitle") }}</h2>
            <p class="text-big">
              {{ $t("service.serviceContent_1") }}
            </p>
            <p class="text">
              {{ $t("service.serviceContent_2") }}
            </p>
            <a class="more martence-button active" href="#">{{ $t("service.serviceButton") }}</a>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-6 col-12">
          <div class="single-service-2">
            <div class="icon">
              <span class="flaticon-concept"></span>
            </div>
            <div class="content">
              <h5 class="title">{{ $t("service.firstTitle") }}</h5>
            </div>
          </div>
          <div class="single-service-2">
            <div class="icon">
              <span class="flaticon-pile"></span>
            </div>
            <div class="content">
              <h3 class="title">
                {{ $t("service.secondTitle") }}
              </h3>
            </div>
          </div>
          <div class="single-service-2">
            <div class="icon">
              <span class="flaticon-pencil"></span>
            </div>
            <div class="content">
              <h3 class="title">
                {{ $t("service.thirdTitle") }}
              </h3>
            </div>
          </div>
          <div class="single-service-2">
            <div class="icon">
              <span class="flaticon-eraser"></span>
            </div>
            <div class="content">
              <h3 class="title">
                {{ $t("service.fourthTitle") }}
              </h3>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-6 col-12">
          <div class="single-service-2">
            <div class="icon">
              <span class="flaticon-eraser"></span>
            </div>
            <div class="content">
              <h3 class="title">{{ $t("service.fifthTitle") }}</h3>
            </div>
          </div>
          <div class="single-service-2">
            <div class="icon">
              <span class="flaticon-document"></span>
            </div>
            <div class="content">
              <h3 class="title">
                {{ $t("service.sixthTitle") }}
              </h3>
            </div>
          </div>
          <div class="single-service-2">
            <div class="icon">
              <span class="flaticon-responsive"></span>
            </div>
            <div class="content">
              <h3 class="title">
                {{ $t("service.seventhTitle") }}
              </h3>
            </div>
          </div>
          <div class="single-service-2">
            <div class="icon">
              <span class="flaticon-concept"></span>
            </div>
            <div class="content">
              <h3 class="title">
               {{ $t("service.eighthTitle") }}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- service-area-end -->
  <!-- counter-area-start -->
  <div class="counter-area bg-with-black">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-3 col-md-3 col-sm-4 col-12">
          <div class="single-counter">
            <div class="icon">
              <span class="flaticon-chat-1"></span>
            </div>
            <div class="content">
              <h2
                class="counter counter-up"
                data-counterup-time="1500"
                data-counterup-delay="30"
              >
                3524
              </h2>
              <p class="name">{{ $t("counter.firstTitle") }}</p>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-3 col-sm-4 col-12">
          <div class="single-counter">
            <div class="icon">
              <span class="flaticon-award"></span>
            </div>
            <div class="content">
              <h2
                class="counter counter-up"
                data-counterup-time="1500"
                data-counterup-delay="30"
              >
                527256
              </h2>
              <p class="name">{{ $t("counter.secondTitle") }}</p>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-3 col-sm-4 col-12">
          <div class="single-counter">
            <div class="icon">
              <span class="flaticon-like-1"></span>
            </div>
            <div class="content">
              <h2
                class="counter counter-up"
                data-counterup-time="1500"
                data-counterup-delay="30"
              >
                5426
              </h2>
              <p class="name">{{ $t("counter.thirdTitle") }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- portfolio-area-start -->
  <div class="portfolio-area">
    <div class="container">
      <div class="row">
        <div
          class="col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-sm-10 offset-sm-1 col-12"
        >
          <div class="section-title">
            <h2 class="title">{{ $t("portfolio.portfolioTitle") }}</h2>
            <p class="text">
              {{ $t("portfolio.portfolioContent") }}
            </p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="portfolio-menu">
            <!-- <button class="button checked" data-filter="*">All</button>
                        <button class="button" data-filter=".animation">animation</button>
                        <button class="button" data-filter=".branding">branding</button>
                        <button class="button" data-filter=".print">print</button>
                        <button class="button" data-filter=".design">design</button> -->
          </div>
        </div>
      </div>
      <div class="row grid_container" id="container">
        <div
          class="col-lg-4 col-md-4 col-sm-6 col-12 grid branding print"
          data-category="post-transition"
        >
          <div class="portfolio-box">
            <a href="" @click.prevent="GoColumn(1)">
              <i class="flaticon-add-circular-outlined-button"></i>
              <img src="~@/assets/img/WeChat1.jpg" alt="" />
            </a>
            <div class="content">
              <h4 class="title">{{ $t("portfolio.firstTitle") }}</h4>
              <p class="text">
                "{{ $t("portfolio.firstContent") }}"
              </p>
            </div>
          </div>
        </div>
        <div
          class="col-lg-4 col-md-4 col-sm-6 col-12 grid animation design"
          data-category="post-transition"
        >
          <div class="portfolio-box">
            <a href="" @click.prevent="GoColumn(2)">
              <i class="flaticon-add-circular-outlined-button"></i>
              <img src="~@/assets/img/WeChat2.jpg" alt="" />
            </a>
            <div class="content">
              <h4 class="title">{{ $t("portfolio.secondTitle") }}</h4>
              <p class="text">
                "{{ $t("portfolio.secondContent") }}"
              </p>
            </div>
          </div>
        </div>
        <div
          class="col-lg-4 col-md-4 col-sm-6 col-12 grid print"
          data-category="post-transition"
        >
          <div class="portfolio-box">
            <a href="" @click.prevent="GoColumn(3)">
              <i class="flaticon-add-circular-outlined-button"></i>
              <img src="~@/assets/img/WeChat3.jpg" alt="" />
            </a>
            <div class="content">
              <h4 class="title">{{ $t("portfolio.thirdTitle") }}</h4>
              <p class="text">
                "{{ $t("portfolio.thirdContent") }}"
              </p>
            </div>
          </div>
        </div>
        <div
          class="col-lg-4 col-md-4 col-sm-6 col-12 grid branding print"
          data-category="post-transition"
        >
          <div class="portfolio-box">
            <a href="" @click.prevent="GoColumn(4)">
              <i class="flaticon-add-circular-outlined-button"></i>
              <img src="~@/assets/img/WeChat4.jpg" alt="" />
            </a>
            <div class="content">
              <h4 class="title">{{ $t("portfolio.fourthTitle") }}</h4>
              <p class="text">
                "{{ $t("portfolio.fourthContent") }}"
              </p>
            </div>
          </div>
        </div>
        <div
          class="col-lg-4 col-md-4 col-sm-6 col-12 grid branding print"
          data-category="post-transition"
        >
          <div class="portfolio-box">
            <a href="" @click.prevent="GoColumn(5)">
              <i class="flaticon-add-circular-outlined-button"></i>
              <img src="~@/assets/img/chatgpt.png" alt="" />
            </a>
            <div class="content">
              <h4 class="title">{{ $t("portfolio.fifthTitle") }}</h4>
              <p class="text">
                "{{ $t("portfolio.fifthConetnt") }}"
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- portfolio-area-end -->
  <!-- counter-area-end -->
  <div style="height: 100px"></div>
  <!-- testimonial-area-start -->
  <div class="testimonial-area bg-with-blue">
    <div class="container">
      <div class="row">
        <div
          class="col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-sm-10 offset-sm-1 col-12"
        >
          <div class="section-title color-white">
            <h2 class="title">{{ $t("testimonial.testimonialTitle") }}</h2>
            <p class="text">
              {{ $t("testimonial.testimonialContent") }}
            </p>
          </div>
        </div>
      </div>
      <div class="testimonial-carousel owl-carousel">
        <div class="single-testimonial">
          <p class="text">
            {{ $t("testimonial.firstContent") }}
          </p>
          <div class="author">
            <div class="img">
              <img
                src="https://img.51miz.com/Element/00/88/82/42/c21e019b_E888242_0f2360ce.png"
                alt=""
              />
            </div>
            <div class="details">
              <h4 class="name">M. T.</h4>
              <p class="desg">{{ $t("testimonial.title") }}</p>
            </div>
          </div>
        </div>
        <div class="single-testimonial">
          <p class="text">
            {{ $t("testimonial.secondContent") }}
          </p>
          <div class="author">
            <div class="img">
              <img
                src="https://img.51miz.com/Element/00/88/82/42/c21e019b_E888242_0f2360ce.png"
                alt=""
              />
            </div>
            <div class="details">
              <h4 class="name">D. M.</h4>
              <p class="desg">{{ $t("testimonial.title") }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- testimonial-area-end -->
  <!-- blog-area-start -->
  <div class="blog-area">
    <div class="container">
      <div class="row">
        <div
          class="col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-sm-10 offset-sm-1 col-12"
        >
          <div class="section-title">
            <h2 class="title">{{ $t("homeblog.blogTitle") }}</h2>
            <p class="text">{{ $t("homeblog.blogContent") }}</p>
          </div>
        </div>
      </div>
      <div v-if="flag" class="row justify-content-center">
        <div class="col-lg-6 col-md-8 col-sm-12 col-12">
          <div class="blog-big">
            <div class="img bg-with-black">
              <img :src="showPage[0].imagefile" alt="" />
              <span class="type">{{ $t("homeblog.blogType") }}</span>
            </div>
            <div class="content">
              <span class="date">{{ showPage[0].time }}</span>
              <h2 class="title">
                <a href="#" @click.prevent="GoToDetail(showPage[0].aid)">{{
                  showPage[0].title
                }}</a>
              </h2>
              <p
                class="text"
                style="
                  width: 95%;
                  overflow: hidden;
                  display: -webkit-box;
                  -webkit-line-clamp: 5;
                  -webkit-box-orient: vertical;
                "
              >
                {{ showPage[0].body }}
              </p>
              <a
                class="more martence-button"
                href="#"
                @click.prevent="GoToDetail(showPage[0].aid)"
                >{{ $t("homeblog.blogButton") }}</a
              >
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-6 col-12">
          <div class="blog-small">
            <span class="type">{{ $t("homeblog.blogType") }}</span>
            <span class="date">{{ showPage[1].time }}</span>
            <h4 class="title">
              <a href="#" @click.prevent="GoToDetail(showPage[1].aid)">{{
                showPage[1].title
              }}</a>
            </h4>
            <p
              class="text"
              style="
                width: 95%;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
              "
            >
              {{ showPage[1].body }}
            </p>
            <a
              class="more martence-button"
              href="#"
              @click.prevent="GoToDetail(showPage[1].aid)"
              >{{ $t("homeblog.blogButton") }}</a
            >
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-6 col-12">
          <div class="blog-small">
            <span class="type">{{ $t("homeblog.blogType") }}</span>
            <span class="date">{{ showPage[2].time }}</span>
            <h4 class="title">
              <a href="#" @click.prevent="GoToDetail(showPage[2].aid)">{{
                showPage[2].title
              }}</a>
            </h4>
            <p
              class="text"
              style="
                width: 95%;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
              "
            >
              {{ showPage[2].body }}
            </p>
            <a
              class="more martence-button"
              href="#"
              @click.prevent="GoToDetail(showPage[2].aid)"
              >{{ $t("homeblog.blogButton") }}</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- blog-area-end -->
</template>
<script>
import axios from "axios";
export default {
  name: "Header",
  data() {
    return {
      showPage: [], //每页展示的内容
      flag: false,
      ip:'',
    };
  },
  methods: {
    GoTop() {
      document.documentElement.scrollTop = 0;
    },
    Change(id) {
      if (id == 1) {
        sessionStorage.setItem("state", 8);
        this.$router.push({ path: "/login" });
      } else if (id == 2) {
        sessionStorage.setItem("state", 9);
        if (this.client.cid != null) {
          this.$router.push({ path: "/personalpage" });
        } else {
          this.$router.push({ path: "/freelancer/homepage" });
        }
      } else if (id == 3) {
        sessionStorage.setItem("state", 2);
        this.$router.push({ path: "/talents" });
      } else if (id == 4) {
        sessionStorage.setItem("state", 3);
        this.$router.push({ path: "/case" });
      }
    },
    GoColumn(id) {
      sessionStorage.setItem("state", 7);
      this.$router.push({ path: "/column", query: { id: id } });
    },
    GoToDetail(id) {
      sessionStorage.setItem("state", 5);
      this.$router.push({ path: "/blogdetail", query: { id: id } });
    },
  },
  setup() {
    let client = JSON.parse(sessionStorage.getItem("client"));
    return {
      client,
    };
  },
  created() {
    const that = this;
    axios
      .get("/api/getallarticle")
      .then(function (resp) {
        that.showPage = resp.data.slice(0, 3);
        that.flag = true;
      })
      .catch((error) => {
        console.log(error);
      });

  },
};
</script>
<style src="../assets/css/style.css" scoped></style>
<style src="../assets/css/flaticon.css" scoped></style>
<style scoped>
@media (min-width: 48em) {
  html {
    font-size: 18px;
  }
}
body {
  color: #5a5a5a;
}
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  color: #333;
}

/* CUSTOMIZE THE CAROUSEL
-------------------------------------------------- */

/* Carousel base class */
.carousel {
  margin-bottom: 4rem;
}
/* Since positioning the image, we need to help out the caption */
.carousel-caption {
  bottom: 3rem;
  z-index: 10;
}

/* Declare heights because of positioning of img element */
.carousel-item {
  height: 40rem;
  background-color: #777;
}
.carousel-item > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* MARKETING CONTENT
-------------------------------------------------- */

/* Center align the text within the three columns below the carousel */
.marketing .col-lg-4 {
  margin-bottom: 1.5rem;
  text-align: center;
}
.marketing h2 {
  font-weight: 400;
}
.marketing .col-lg-4 p {
  margin-right: 0.75rem;
  margin-left: 0.75rem;
}

/* Featurettes
------------------------- */

.featurette-divider {
  margin: 5rem 0; /* Space out the Bootstrap <hr> more */
}

/* Thin out the marketing headings */
.featurette-heading {
  font-weight: 300;
  line-height: 1;
  letter-spacing: -0.05rem;
}

/* RESPONSIVE CSS
-------------------------------------------------- */

@media (min-width: 40em) {
  /* Bump up size of carousel content */
  .carousel-caption p {
    margin-bottom: 1.25rem;
    font-size: 1.25rem;
    line-height: 1.4;
  }

  .featurette-heading {
    font-size: 50px;
  }
}

@media (min-width: 62em) {
  .featurette-heading {
    margin-top: 7rem;
  }
}

#TOPUp {
  position: fixed;
  right: 45px;
  bottom: 100px;
  width: 40px;
  height: 40px;
  z-index: 99999999;
  box-shadow: 0px 0px 4px 4px #ecefef;
  border-radius: 600px;
}
</style>
